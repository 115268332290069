import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    columnStyle: {
        fontSize: 11,
        padding: 0
    },
    tableDataStyle: {
        fontSize: 16,
        padding: 0
    }
});

const columns = [
    {title: 'Food Intake Log', width: '22.5%', key: 'food_intake_log', align: 'left', },
    {title: 'Time', width: '12.5%', key: 'time', align: 'right',},
    {title: 'Serving', width: '10%', key: 'serving', align: 'right',},
    {title: 'Carbs', width: '12.5%', key: 'carbohydrate', align: 'right',},
    {title: 'Fat', width: '10%', key: 'fat', align: 'right',},
    {title: 'Protein', width: '10%', key: 'protein', align: 'right',},
    {title: 'Cal', width: '10%', key: 'calorie', align: 'right',}
];

const actionButtonWidth = '12%';

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                {
                    columns.map(col => (
                       <TableCell align={col.align} className={classes.tableDataStyle} style={{width: col.width, backgroundColor: col.color}}>
                           {row[col.key]}
                       </TableCell>
                    ))
                }
                <TableCell style={{width: actionButtonWidth}}>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={0}>
                            <Table>
                                <TableBody>
                                    {
                                        row.food_items.map(food => (
                                           <TableRow className={classes.root}>
                                               <TableCell style={{width: addPercent(columns[0].width, columns[1].width)}} className={classes.tableDataStyle}>
                                                   <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                       <Avatar src={food.img} style={{borderRadius: 0}} />
                                                       <Typography style={{marginLeft: 5, marginRight: 5}}>
                                                           {food.food_name}
                                                       </Typography>
                                                   </Box>
                                               </TableCell>
                                               <TableCell style={{width: columns[2].width}} align='right' className={classes.tableDataStyle}>
                                                   {food.serving}
                                               </TableCell>
                                               <TableCell style={{width: columns[3].width}} align='right' className={classes.tableDataStyle}>
                                                   {food.carbohydrate}
                                               </TableCell>
                                               <TableCell style={{width: columns[4].width}} align='right' className={classes.tableDataStyle}>
                                                   {food.protein}
                                               </TableCell>
                                               <TableCell style={{width: columns[5].width}} align='right' className={classes.tableDataStyle}>
                                                   {food.fat}
                                               </TableCell>
                                               <TableCell style={{width: columns[6].width}} align='right' className={classes.tableDataStyle}>
                                                   {food.calorie}
                                               </TableCell>
                                               <TableCell style={{width: actionButtonWidth}} />
                                           </TableRow>
                                        ))
                                    }
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired,
            }),
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired,
    }).isRequired,
};

export default function CollapsibleTable(props) {
    const classes = useRowStyles();
    const {rows} = props;
    return (
        <TableContainer component={Box}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {
                            columns.map(col => (
                               <TableCell align={col.align} className={classes.columnStyle} style={{width: col.width, backgroundColor: col.color}}>
                                   {col.title}
                               </TableCell>
                            ))
                        }
                        <TableCell style={{width: actionButtonWidth}} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.food_intake_log} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function addPercent(p1, p2) {
    return parseFloat(p1.split('%')[0]) + parseFloat(p2.split('%')[0]) + '%';
}
