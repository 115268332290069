import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ButtonBase, Modal, Box, Typography, Grid, Avatar, TextField} from "@material-ui/core";
import {Colors} from "../../styles/Colors";
import {IncrDecrCompWithText} from "./IncrementDecrementComponent";
import {weeklyWeightGoals} from "./weight/WeightGoalInputs";
import {DropDownSelector} from "../main/dropDownSelector";
import {GoalTextInputComponent} from "./TextInputComponent";
import {allIsValid, isMoreThanTwoDecimalPlaces, isNumeric, withRestriction} from "../../commonFuncs/graphFuncs";
import {requestDeletePreconfiguredGoal, requestUpsertNewPreconfiguredGoal} from "../../netCalls/requestsGoalModule";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    goalNameTextInput: {
        width: '100%',
    },
    actionButtonsContainer: {
        paddingLeft: '20%',
        paddingRight: '20%'
    },
    leftButton: {
        width: '100%',
        backgroundColor: Colors.grey,
        borderRadius: 8,
        height: 42
    },
    leftButtonText: {
        fontWeight: 'bold',
    },
    rightButton: {
        width: '100%',
        backgroundColor: Colors.loginColorArr[1],
        borderRadius: 8,
        height: 42
    },
    rightButtonText: {
        fontWeight: 'bold',
        color: '#fff'
    },
    disabledRightButton: {
        width: '100%',
        backgroundColor: Colors.loginColorArr[1],
        borderRadius: 8,
        height: 42,
        opacity: 0.5
    }
}));

// the base layout with goal name, two customisable buttons at the bottom.
// note that goal name is disabled
function GoalCommonTemplateComponent(props) {
    const {onLeftButton, onRightButton, goalName, setGoalName, children, isDisabled, leftButtonText, rightButtonText} = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField label={'Goal Name'}
                               margin='dense'
                               variant='outlined'
                               disabled={true}
                               className={classes.goalNameTextInput}
                               value={goalName}
                               onChange={(e) => setGoalName(e.target.value)} />
                </Grid>
                {
                    children
                }
            </Grid>
            <Grid container spacing={6} className={classes.actionButtonsContainer}>
                <Grid item xs={6}>
                    <ButtonBase className={classes.leftButton} onClick={onLeftButton}>
                        <Typography className={classes.leftButtonText}>{leftButtonText}</Typography>
                    </ButtonBase>
                </Grid>
                <Grid item xs={6}>
                    <ButtonBase className={isDisabled? classes.disabledRightButton : classes.rightButton}
                                disabled={isDisabled} onClick={onRightButton}>
                        <Typography className={classes.rightButtonText}>{rightButtonText}</Typography>
                    </ButtonBase>
                </Grid>
            </Grid>
        </Box>
    )
}

// Note that for components below, the values are in string format. - should parse first before submitting.
function EditBloodGlucoseGoalComponent(props) {
    const {onCancel, initialData, onDone} = props;
    const [goalName, setGoalName] = useState(initialData.name);
    const [minValue, setMinValue] = useState(initialData.min_bg);
    const [maxValue, setMaxValue] = useState(initialData.max_bg);
    const classes = useStyles();

    const isDisabled = !allIsValid([minValue, maxValue]);

    const onSave = () => {
        // do the logic for saving - async call
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            max_bg: parseFloat(maxValue),
            min_bg: parseFloat(minValue),
        }, 'blood_glucose')
            .then(resp => onDone());
    }

    return (
        <GoalCommonTemplateComponent onLeftButton={onCancel}
                                     onRightButton={onSave}
                                     leftButtonText={'Cancel'}
                                     rightButtonText={'Save'}
                                     goalName={goalName}
                                     setGoalName={setGoalName}
                                     isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Min Value'}
                                        unit={'mmol/L'}
                                        currentValue={minValue}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setMinValue)}
                                        isValid={isNumeric}
                                        helperText={'Min value must be numeric'} />
                <GoalTextInputComponent title={'Max Value'}
                                        unit={'mmol/L'}
                                        currentValue={maxValue}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setMaxValue)}
                                        isValid={isNumeric}
                                        helperText={'Max value must be numeric'} />
            </Grid>
        </GoalCommonTemplateComponent>
    );
}

function EditFoodIntakeGoalComponent(props) {
    const {onCancel, initialData, onDone} = props;
    const [goalName, setGoalName] = useState(initialData.name);
    const [calorieGoal, setCalorieGoal] = useState(initialData.calories);
    const [carbohydrateGoal, setCarbohydrateGoal] = useState(initialData.carbs);
    const [proteinGoal, setProteinGoal] = useState(initialData.protein);
    const [fatGoal, setFatGoal] = useState(initialData.fats);

    const classes = useStyles();

    const onSave = () => {
        // do the logic for saving - async call
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            carbs: parseFloat(carbohydrateGoal),
            fats: parseFloat(fatGoal),
            protein: parseFloat(proteinGoal),
            calories: parseFloat(calorieGoal)
        }, 'food')
            .then(resp => onDone());
    }

    const isDisabled = !allIsValid([calorieGoal, carbohydrateGoal, proteinGoal, fatGoal]);

    return (
        <GoalCommonTemplateComponent onLeftButton={onCancel}
                                     onRightButton={onSave}
                                     leftButtonText={'Cancel'}
                                     rightButtonText={'Save'} goalName={goalName}
                                     setGoalName={setGoalName}
                                     isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Cal'}
                                        unit={'kcal'}
                                        currentValue={calorieGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setCalorieGoal)}
                                        isValid={isNumeric}
                                        helperText={'Calories must be numeric'} />
                <GoalTextInputComponent title={'Carbs'}
                                        unit={'g'}
                                        currentValue={carbohydrateGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setCarbohydrateGoal)}
                                        isValid={isNumeric}
                                        helperText={'Carbohydrates burnt must be numeric'} />
                <GoalTextInputComponent title={'Protein'}
                                        unit={'g'}
                                        currentValue={proteinGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setProteinGoal)}
                                        isValid={isNumeric}
                                        helperText={'Protein must be numeric'} />
                <GoalTextInputComponent title={'Fat'}
                                        unit={'g'}
                                        currentValue={fatGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setFatGoal)}
                                        isValid={isNumeric}
                                        helperText={'Fat must be numeric'} />
            </Grid>
        </GoalCommonTemplateComponent>
    );
}

function EditMedicationGoalComponent(props) {
    const {onCancel, initialData, onDone} = props;
    const [goalName, setGoalName] = useState(initialData.name);
    const classes = useStyles();

    const isDisabled = false;

    const onSave = () => {
        // do the logic for saving - async call
    }

    return (
        <GoalCommonTemplateComponent onLeftButton={onCancel}
                                     onRightButton={onSave}
                                     leftButtonText={'Cancel'}
                                     rightButtonText={'Save'} goalName={goalName}
                                     setGoalName={setGoalName}
                                     isDisabled={isDisabled}>
            <Grid item xs={6}>
                {null}
            </Grid>
        </GoalCommonTemplateComponent>
    );
}

function EditWeightGoalComponent(props) {
    const {onCancel, initialData, onDone} = props;
    const [goalName, setGoalName] = useState(initialData.name);
    const [weightGoal, setWeightGoal] = useState(initialData.goal_weight);
    const [weeklyWeightGoal, setWeeklyWeightGoal] = useState(initialData.weekly_offset);

    const classes = useStyles();

    const isDisabled = !allIsValid([weightGoal]);

    const onSave = () => {
        // do the logic for saving - async call
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            goal_weight: parseFloat(weightGoal),
            weekly_offset: parseFloat(weeklyWeightGoal),
        }, 'weight')
            .then(resp => onDone());
    }

    return (
        <GoalCommonTemplateComponent onLeftButton={onCancel}
                                     onRightButton={onSave}
                                     leftButtonText={'Cancel'}
                                     rightButtonText={'Save'}
                                     goalName={goalName}
                                     setGoalName={setGoalName}
                                     isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Target Weight'}
                                        unit={'kg'}
                                        currentValue={weightGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setWeightGoal)}
                                        isValid={isNumeric}
                                        helperText={'Weight must be numeric'} />
            </Grid>
            <Grid item xs={6}>
                <DropDownSelector choices={weeklyWeightGoals}
                                  currentChoiceValue={weeklyWeightGoal}
                                  onChoiceChange={setWeeklyWeightGoal}
                                  style={{width: '100%'}}
                />
            </Grid>
        </GoalCommonTemplateComponent>
    );
}

function EditActivityGoalComponent(props) {
    const {onCancel, initialData, onDone} = props;
    const [goalName, setGoalName] = useState(initialData.name);
    const [durationGoal, setDurationGoal] = useState(initialData.duration);
    const [calorieBurntGoal, setCalorieBurntGoal] = useState(initialData.cal_burnt);

    const classes = useStyles();

    const isDisabled = !allIsValid([durationGoal, calorieBurntGoal]);

    const onSave = () => {
        // do the logic for saving - async call
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            cal_burnt: parseFloat(calorieBurntGoal),
            duration: parseFloat(durationGoal),
        }, 'activity')
            .then(resp => onDone());
    }

    return (
        <GoalCommonTemplateComponent onLeftButton={onCancel}
                                     onRightButton={onSave}
                                     leftButtonText={'Cancel'}
                                     rightButtonText={'Save'}
                                     goalName={goalName}
                                     setGoalName={setGoalName}
                                     isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Duration'}
                                        unit={'min'}
                                        currentValue={durationGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setDurationGoal)}
                                        isValid={isNumeric}
                                        helperText={'Duration must be numeric'} />
                <GoalTextInputComponent title={'Cal Burnt'}
                                        unit={'kcal'}
                                        currentValue={calorieBurntGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setCalorieBurntGoal)}
                                        isValid={isNumeric}
                                        helperText={'Calories burnt must be numeric'} />
            </Grid>
        </GoalCommonTemplateComponent>
    );
}

function EditStepsGoalComponent(props) {
    const {onCancel, initialData, onDone} = props;
    const [goalName, setGoalName] = useState(initialData.name);
    const [minStepsGoal, setMinStepsGoal] = useState(initialData.steps);

    const classes = useStyles();

    const isDisabled = !allIsValid([minStepsGoal]);

    const onSave = () => {
        // do the logic for saving - async call
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            steps: parseFloat(minStepsGoal),
        }, 'steps')
            .then(resp => onDone());
    }

    return (
        <GoalCommonTemplateComponent onLeftButton={onCancel}
                                     onRightButton={onSave}
                                     leftButtonText={'Cancel'}
                                     rightButtonText={'Save'}
                                     goalName={goalName}
                                     setGoalName={setGoalName}
                                     isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Min Steps'}
                                        unit={'steps'}
                                        currentValue={minStepsGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setMinStepsGoal)}
                                        isValid={isNumeric}
                                        helperText={'Duration must be numeric'} />
            </Grid>
        </GoalCommonTemplateComponent>
    );
}

export {
    EditActivityGoalComponent,
    EditBloodGlucoseGoalComponent,
    EditFoodIntakeGoalComponent,
    EditMedicationGoalComponent,
    EditStepsGoalComponent,
    EditWeightGoalComponent
};
