import React, {useRef, useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Box, TextField} from "@material-ui/core";
import {Colors} from "../../styles/Colors";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../../redux/reduxMapping";
import {LoginForm, AnswerQuestionsForm, ChangePasswordForm, ForgotPasswordForm} from "./LoginSubForms";
import axios from 'axios'


const LoginComponent = props => {
    const classes = useStyles();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [loginSequence, setSequence] = useState(0);
    const [questions, setQuestions] = useState([]);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const token = useRef('');

    const changePassword = (newPass) => {
        let url = `auth/physician/password-reset/reset`;
        const authData = {
            newPassword: newPass
        };
        const instance = axios.create({
            headers: {
                common: {
                    'Authorization': token.current,
                },
            },
        });
        instance.post(url, authData)
            .then(response=> {
                setPasswordResetSuccess(true);
                setSequence(0)
            })
            .catch(err=>{
                console.log(err.message);
            })
    };

    const initForgot = () => {
        setSequence(1);
    };

    const handleClose = (event, reason) => {
        setPasswordResetSuccess(false);
    };

    return (
        <Box className={[props.className, classes.container]} >
            {loginSequence === 0 && (
                <LoginForm initForgot={initForgot}
                           passwordResetSuccess={passwordResetSuccess}
                           handleClose={handleClose}
                           username={username}
                           setUsername={setUsername}
                           password={password}
                           setPassword={setPassword}
                           login={props.login}
            />)}
            {loginSequence === 1 && (
                <ForgotPasswordForm setSequence={setSequence} username={username} setUsername={setUsername}
                                    setQuestions={setQuestions}/>
            )}
            {loginSequence === 2 && (
                <AnswerQuestionsForm questions={questions} username={username} token={token} setSequence={setSequence}/>
            )}
            {loginSequence === 3 && (
                <ChangePasswordForm changePassword={changePassword} />
            )}
        </Box>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        background: 'linear-gradient(to bottom left, ' + Colors.loginColorArr + ')',
    },
});

//this is test for change textfield style
const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'green',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'green',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green',
            },
        },
    },
})(TextField);

export default connect(mapStateToProps, mapDispatchToProps)(LoginComponent);
