import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Box, TextField} from "@material-ui/core";
import React, {Fragment, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../../redux/reduxMapping";
import {withStyles} from "@material-ui/core/styles";

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 32px',
        width: '30%',
        height: 55,
        lineHeight: 1.5,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

    },
})(Button);

const SecurityQuestionSetupDialog = (props) => {
    const [selectedQuestions, setSelectedQuestions] = useState(['', '', '']);
    const [questionContents, setQuestionContents] = useState(['', '', '']);
    const [answers, setAnswers] = useState(['', '', '']);
    const [questionErrors, setQuestionErrors] = useState([false, false, false]);
    const [answerErrors, setAnswerErrors] = useState([false, false, false]);


    const handleSubmit = (e) => {
        e.preventDefault();
        props.submit(selectedQuestions, answers);
    };

    return  <Dialog aria-labelledby="customized-dialog-title" open={props.open}
                    disableBackdropClick={true} disableEscapeKeyDown={true}>
        <DialogTitle id="customized-dialog-title" style={{paddingBottom: 0}}>
            <p style={{fontSize:"36px", margin: 0}}><b>Set Up</b></p>
            <p style={{margin: 0}}><b>Security Questions</b></p>
            <p style={{margin: 0, lineHeight: "normal"}}>These security questions will be used to verify your identity and help recover your password if you ever forget it.</p>
        </DialogTitle>
        <DialogContent style={{paddingTop: 0}}>
            <Box component="form" display="flex" flexDirection="column" p={1} m={1} bgcolor="background.paper"
                 onSubmit={handleSubmit} style={{margin: 0, padding: 0}}>
                {[1, 2, 3].map((questionNumber, index)=>{
                    return (
                        <Fragment>
                            <FormControl required={true}>
                                <InputLabel id={`security-question-select-label${questionNumber}`}>{`Security Question ${questionNumber}`}</InputLabel>
                                <Select
                                    labelId={`security-question-select-label${questionNumber}`}
                                    value={questionContents[index]}
                                    error={questionErrors[index]}
                                    onChange={(e)=>{
                                        questionContents[index] = e.target.value;
                                        setQuestionContents([...questionContents]);
                                        selectedQuestions[index] = props.allQuestions.find((element) => element.content === e.target.value)
                                        setSelectedQuestions([...selectedQuestions])
                                    }} >
                                    {
                                        props.allQuestions.filter(function(question){
                                            return selectedQuestions.indexOf(question) === -1 || selectedQuestions.indexOf(question) === index;
                                        }).map((question, index) => {
                                            return (<MenuItem value={question.content} key={question._id}>{question.content}</MenuItem>);
                                        })
                                    }
                                </Select>
                            </FormControl>
                            <TextField
                                style={{marginTop: 20, width: '60%', height: 60}}
                                label="Answer"
                                required={true}
                                error={answerErrors[index]}
                                variant="outlined"
                                value={answers[index]}
                                onChange={(e) => {
                                    const updated = [...answers];
                                    updated[index] = e.target.value;
                                    setAnswers(updated)
                                }}
                            />
                        </Fragment>
                    )
                })}
                <Box display="flex"  alignItems="center"
                     justifyContent="center">
                    <BootstrapButton style={{margin: 10}} disableRipple variant="contained" onClick={() => { props.cancel() }}>
                        <b>{props.cancelText}</b>
                    </BootstrapButton>
                    <BootstrapButton style={{margin: 10, backgroundColor: '#16a950',
                        borderColor: '#16a950'}} disableRipple variant="contained" type='submit' color="primary">
                        <b>{props.submitText}</b>
                    </BootstrapButton>
                </Box>
            </Box>
        </DialogContent>

    </Dialog>

};

export default SecurityQuestionSetupDialog;