import React from 'react';
import {ButtonBase, Modal, Box, Typography, Grid, Avatar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {globalStyles} from "../../styles/globalStyles";
import {
    EditActivityGoalComponent,
    EditBloodGlucoseGoalComponent,
    EditFoodIntakeGoalComponent,
    EditMedicationGoalComponent, EditStepsGoalComponent, EditWeightGoalComponent
} from "./VariousEditGoalComponents";

const useStyles = makeStyles((theme) => ({
    modalContent: {
        width: '50%',
        height: '450px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        outline: "none",
        borderRadius: 16,
        padding: '2%'
    },
    goalButton: {
        width: '100%',
        height:  80,
        border: '1px solid #dfdfdf',
        borderRadius: 8,
        padding: '4%'
    },
    subTitle: {
        ...globalStyles.pageSubTitleSmall,
        marginBottom: 10
    }
}));

function EditGoalModal(props) {
    const {visible, handleClose, initialData} = props;
    const classes = useStyles();

    return visible && (
        <Modal open={visible} onClose={handleClose} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <Box className={classes.modalContent}>
                <Typography style={globalStyles.pageTitleMedium}>Edit Goal</Typography>
                {
                    initialData.goal_type === 'blood_glucose' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Blood Glucose Goal</Typography>
                            <EditBloodGlucoseGoalComponent onCancel={handleClose}
                                                           initialData={initialData}
                                                           onDone={handleClose}  />
                        </>
                    )
                }
                {
                    initialData.goal_type === 'food' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Food intake Goal</Typography>
                            <EditFoodIntakeGoalComponent onCancel={handleClose}
                                                         initialData={initialData}
                                                         onDone={handleClose} />
                        </>
                    )
                }
                {
                    initialData.goal_type === 'medication' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Medication Goal</Typography>
                            <EditMedicationGoalComponent onCancel={handleClose}
                                                         initialData={initialData}
                                                         onDone={handleClose} />
                        </>
                    )
                }
                {
                    initialData.goal_type === 'weight' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Weight Goal</Typography>
                            <EditWeightGoalComponent onCancel={handleClose}
                                                     initialData={initialData}
                                                     onDone={handleClose} />
                        </>
                    )
                }
                {
                    initialData.goal_type === 'activity' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Activity Goal</Typography>
                            <EditActivityGoalComponent onCancel={handleClose}
                                                       initialData={initialData}
                                                       onDone={handleClose} />
                        </>
                    )
                }
                {
                    initialData.goal_type === 'steps' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Steps Goal</Typography>
                            <EditStepsGoalComponent onCancel={handleClose}
                                                    initialData={initialData}
                                                    onDone={handleClose} />
                        </>
                    )
                }
            </Box>
        </Modal>
    )
}

export {EditGoalModal};
