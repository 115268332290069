import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box} from "@material-ui/core";
import Home from "../../screens/Home";
import PatientInfo from "../../screens/PatientInfo";
import Appointments from "../../screens/Appointments";
import SetGoal from "../../screens/SetGoal";
import Chat from "../../screens/Chat";
import {Colors} from "../../styles/Colors";
import AccountView from "../../screens/AccountView";


const MainContent = props => {
    const classes = useStyles();
    const {selectedTab} = props;
    return (
        <Box className={[props.className, classes.container]}>
            {selectedTab === 0 && (<Home />)}
            {selectedTab === 1 && (<PatientInfo />)}
            {selectedTab === 2 && (<Appointments />)}
            {selectedTab === 3 && (<SetGoal />)}
            {selectedTab === 4 && (<Chat />)}
            {selectedTab === 5 && (<AccountView />)}
        </Box>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        backgroundColor: Colors.greyBackgroundColor,
    },
});

export default MainContent;
