import {appointments, all_patient_profiles} from "./urls";
import {baseRequestGet, baseRequestPost, baseRequestDelete} from "./requestBase";


const requestGetAppointments = (startDate, endDate) => {
    console.log('startDate : ' + startDate + ' endDate : ' + endDate)
    const params = {
        start: startDate,
        end: endDate,
    };
    let url = appointments + '?start=' + startDate + '&end=' + endDate;
    return baseRequestGet(url, 'requestGetAppointments');
}

const requestAddAppointment = (patient, title, start, end) => {
    const params = {
        patient: patient,
        title: title,
        start: start,
        end: end,
    };
    return baseRequestPost(appointments, 'requestAddAppointment', params);
}

const requestEditAppointment = (id, title, start, end) => {
    const params = {
        _id: id,
        title: title,
        start: start,
        end: end,
    };
    return baseRequestPost(appointments, 'requestEditAppointment', params);
}

const requestDeleteAppointment = (id) => {
    let url = appointments + '?id=' + id;
    return baseRequestDelete(url, 'requestDeleteAppointment');
}

const requestAllPatientsProfile = () => {
    return baseRequestGet(all_patient_profiles, 'requestAllPatientsProfile')
}

const requestGetPatientProfile = patient => {
    const params = {
        patient: patient,
    };
    return baseRequestGet(all_patient_profiles, 'requestGetPatientProfile', params)
}

export {requestGetAppointments, requestAddAppointment, requestEditAppointment, requestDeleteAppointment, requestAllPatientsProfile, requestGetPatientProfile}
