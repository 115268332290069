import React from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: '#fff',
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
        border: '1px solid #cdcdcd',
        height: 38 // must be same as inputRoot
    },
    searchIcon: {
        paddingLeft: 10,
        paddingRight: 10,
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
        padding: 0,
        height: 38, // must be same as search
    },
    inputText: {
        paddingLeft: `calc(1em + ${theme.spacing(3)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%'
    }
}));

// 2 props: value and onValueChange
// value is a string that shows the current text inside the search bar
// onValueChange is a callback fired when the searchbar value changes
// both must be hook up in parent component for it work. handle search filter actions in parent component.
function Searchbar(props) {
    const {value, onValueChange, placeholder} = props;
    const classes = useStyles();

    const onInputTextChange = (event) => {
        if (onValueChange) {
            onValueChange(event.target.value);
        }
    }

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon style={{color: '#4d4d4d'}} />
            </div>
            <InputBase
                placeholder={placeholder || "Search…"}
                autoFocus={true}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputText,
                }}
                inputProps={{ 'aria-label': 'search',
                    value: value || '',
                    onChange: onInputTextChange,
                }}
            />
        </div>
    )
}

function useSearchBar(placeholder) {
    const [value, setValue] = React.useState(''); //Default value is empty string

    return [
        value,
        <Searchbar value={value} onValueChange={setValue} placeholder={placeholder} />,
        setValue
    ];

}

export {useSearchBar, Searchbar};
