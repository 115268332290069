import React from "react";
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";


const AppointmentHead = props => {
    const classes = useStyles();

    const {appointmentList} = props;

    const countClash = () => {
        let counter = 0
        for(let i=0; i<appointmentList.length; i++){
            if(appointmentList[i].clash){
                counter ++
            }
        }
        return counter
    }

    return (
        <Box className={[classes.container, props.className]} border={1} boxShadow={2}>
            <Grid container spacing={3}>
                <Grid item xs={9}>
                    <Box className={classes.subContainer} component={'b'}>
                        Today's Appointment
                    </Box>
                </Grid>
                {countClash() > 0 && (<Grid item xs={3}>
                    <Box className={[classes.subContainer, classes.redText]} component={'b'}>
                        {countClash()} Appointment Clash
                    </Box>
                </Grid>)}
            </Grid>
        </Box>
    )
}

export default AppointmentHead

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        borderRadius: 10,
        borderColor: Colors.grey,
        backgroundColor: Colors.white,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    },
    redText: {
        color: Colors.red
    }
})
