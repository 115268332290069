import React from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import {Legend} from "./legend";

const pieSliceColor = {
    carbohydrate: "#73CA93",
    protein: "#005C30",
    fat: "#16A850",
}

function PieChart(props) {
    const {data} = props;

    const sliceColors = data.labels.map(l => pieSliceColor[l.toLowerCase()]);

    // just append new properties to dataset
    data.datasets.map(dataset => {
       dataset.backgroundColor = sliceColors;
       const total = dataset.data.reduce((acc, curr, index) => acc + curr, 0);
       dataset.data = dataset.data.map(y => y / total);
    });

    // append options
    const options = {
        animation: {
            duration: 0,
            animateRotate: false
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        layout: {
            padding: {
                top: 18
            }
        },
        plugins: {
            datalabels: {
                backgroundColor: function (context) {
                    return context.dataset.backgroundColor;
                },
                borderColor: 'white',
                borderRadius: 25,
                borderWidth: 2,
                color: 'white',
                font: {
                    weight: 'bold'
                },
                anchor: 'end',
                padding: 6,
                formatter: (e) => (Math.round(e * 1000) / 10).toFixed(1) + "%"
            }
        },
        tooltips: {
            enabled: false
        }
    };

    return (
        <div style={{width: '100%', height: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                {
                    data.labels.map(label => (
                       <Legend text={label[0].toUpperCase() + label.slice(1)} color={pieSliceColor[label.toLowerCase()]} size={16} />
                    ))
                }
            </div>
            <div style={{height: '82.5%'}}>
                <Doughnut data={data} options={options} />
            </div>
        </div>
    )
}

export {PieChart};
