import React from 'react';
import {ButtonBase, Modal, Box, Typography, Grid, Avatar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {
    AddActivityGoalComponent,
    AddBloodGlucoseGoalComponent,
    AddFoodIntakeGoalComponent,
    AddMedicationGoalComponent, AddStepsGoalComponent, AddWeightGoalComponent
} from "./VariousAddGoalComponents";
import {globalStyles} from "../../styles/globalStyles";

const useStyles = makeStyles((theme) => ({
    modalContent: {
        width: '50%',
        height: '450px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        outline: "none",
        borderRadius: 16,
        padding: '2%'
    },
    goalButton: {
        width: '100%',
        height:  80,
        border: '1px solid #dfdfdf',
        borderRadius: 8,
        padding: '4%'
    },
    subTitle: {
        ...globalStyles.pageSubTitleSmall,
        marginBottom: 10
    }
}));

const goals = [
    {id: 'blood-glucose', name: 'Blood Glucose Goal', img: 'images/main/svg/hcw-icon-navy-bloodglucose.svg'},
    {id: 'food', name: 'Food Intake Goal', img: 'images/main/svg/hcw-icon-navy-food.svg'},
    {id: 'medication', name: 'Medication Goal', img: 'images/main/svg/hcw-icon-navy-med.svg'},
    {id: 'weight', name: 'Weight Goal', img: 'images/main/svg/hcw-icon-navy-weight.svg'},
    {id: 'activity', name: 'Activity Goal', img: 'images/main/svg/hcw-icon-navy-running.svg'},
    {id: 'steps', name: 'Steps Goal', img: 'images/main/svg/hcw-icon-navy-steps.svg'}
]

function AddGoalModal(props) {
    const {visible, handleClose} = props;
    const [goalType, setGoalType] = React.useState(null);

    const onClose = () => {
        handleClose();
        setGoalType(null);
    }

    const onBack = () => {
        setGoalType(null);
    }

    const classes = useStyles();
    return (
        <Modal open={visible} onClose={onClose} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
            <Box className={classes.modalContent}>
                <Typography style={globalStyles.pageTitleMedium}>Add Goal</Typography>
                {
                    goalType === null && (
                        <>
                            <Typography className={classes.subTitle}>Select Goal Type</Typography>
                            <Grid container spacing={2}>
                                {
                                    goals.map(goal => (
                                       <Grid item xs={6}>
                                           <ButtonBase className={classes.goalButton} onClick={()=>setGoalType(goal.id)}>
                                               <Box style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                                   <Avatar src={goal.img} style={{borderRadius: 0}} />
                                                   <Typography style={{flex: 1, textAlign: 'left', paddingLeft: 24}}>{goal.name}</Typography>
                                                   <Avatar src='images/main/svg/hcw-icon-grey-chevron-right.svg' style={{borderRadius: 0}} />
                                               </Box>
                                           </ButtonBase>
                                       </Grid>
                                    ))
                                }
                            </Grid>
                        </>
                    )
                }
                {
                    goalType === 'blood-glucose' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Blood Glucose Goal</Typography>
                            <AddBloodGlucoseGoalComponent onBack={onBack} onDone={onClose} />
                        </>
                    )
                }
                {
                    goalType === 'food' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Food intake Goal</Typography>
                            <AddFoodIntakeGoalComponent onBack={onBack} onDone={onClose} />
                        </>
                    )
                }
                {
                    goalType === 'medication' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Medication Goal</Typography>
                            <AddMedicationGoalComponent onBack={onBack} onDone={onClose} />
                        </>
                    )
                }
                {
                    goalType === 'weight' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Weight Goal</Typography>
                            <AddWeightGoalComponent onBack={onBack} onDone={onClose} />
                        </>
                    )
                }
                {
                    goalType === 'activity' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Activity Goal</Typography>
                            <AddActivityGoalComponent onBack={onBack} onDone={onClose} />
                        </>
                    )
                }
                {
                    goalType === 'steps' && (
                        <>
                            <Typography style={globalStyles.pageTitleSmall}>Steps Goal</Typography>
                            <AddStepsGoalComponent onBack={onBack} onDone={onClose} />
                        </>
                    )
                }
            </Box>
        </Modal>
    )
}

export {AddGoalModal};
