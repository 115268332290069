import {auth, authCheckState, logout, setSecurityQuestionAnswers} from "./actions";

const mapStateToProps = (state) => {
  return {
    isLogin: state.authReducer.token != null && !state.authReducer.additionalChecking,
    setup: state.authReducer.setup,
    loginError: state.authReducer.error,
    allQuestions: state.authReducer.allQuestions
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (username, password, remember)=> dispatch(auth(username, password, remember)),
    logout: () => dispatch(logout()),
    onTryAutoSignup: ()=> dispatch(authCheckState()),
    setSecurityQuestionAnswers: (q, a)=> dispatch(setSecurityQuestionAnswers(q, a))
  };
};

export {mapStateToProps, mapDispatchToProps};
