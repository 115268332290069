import React from "react";
import {Box, ButtonBase, CardMedia, Grid, Menu, MenuItem} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";


const AppointmentAdd = props => {
    const classes = useStyles();

    const {addAppointment} = props;

    return (
        <Box className={[classes.container, props.className]} border={1} boxShadow={2}>
            <ButtonBase onClick={() => {addAppointment()}}>
                 <CardMedia className={classes.icon} component="img" src="/images/main/svg/hcw-icon-lightgreen-add.svg"/>
                 <Box className={classes.textGreen} >
                            Add Appointment
                 </Box>
            </ButtonBase>

        </Box>
    )
}

export default AppointmentAdd

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        borderRadius: 10,
        borderColor: Colors.grey,
        backgroundColor: Colors.white,
        padding: 10,
        justifyContent: 'start',
        alignItems: 'center',
    },
    icon: {
        width: 40,
        height: '100%',
        aspectRatio:1,
        objectFit: 'contain',
    },
    textGreen: {
        marginLeft: 30,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        color: Colors.lightGreen,
        fontSize: 20,
    },
    menuItem: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
    }
})
