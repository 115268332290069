import * as types from "../constants";
import axiosInstance from '../../util/axios';
import jwt from 'jwt-decode';
import {requestLogin, requestSecurityQuestions, requestAllSecurityQuestions, requestSetSecurityQuestions} from "../../netCalls/requestsLoginModule";
import {key_token, key_first_name, key_username, key_expiration_date} from "../../localStorage/localStorageKeys";


export const authStart = ()=> {
    return {
        type: types.AUTH_START
    };
};

export const authSuccess = (token, firstName) => {
    axiosInstance.defaults.headers.common['Authorization'] = token;
    return {
        type: types.AUTH_SUCCESS,
        token: token,
        firstName: firstName
    };
};

export const authFail = (error) => {
    return {
        type: types.AUTH_FAIL,
        error: error
    };
};

export const setupSuccess = () => {
    return {
        type: types.SETUP_SUCCESS,
    };
};

export const setupStart = () => {
    return {
        type: types.SETUP_START
    }
};

export const logout = () => {
    localStorage.removeItem(key_token);
    localStorage.removeItem(key_expiration_date);
    localStorage.removeItem(key_first_name);
    return {
        type: types.AUTH_LOGOUT
    }
};

export const auth = (username, password, remember) => {
    return dispatch => {
        dispatch(authStart());
        requestLogin(username, password, remember)
            .then(response=> {
                console.log('token : ' + response.data.token);
                const expirationDate = new Date().getTime() + response.data.expires_in * 1000;
                const decoded = jwt(response.data.token);
                localStorage.setItem(key_token, response.data.token);
                localStorage.setItem(key_expiration_date, expirationDate);
                localStorage.setItem(key_first_name, decoded.first_name);
                localStorage.setItem(key_username, decoded.username);
                dispatch(authSuccess(response.data.token, decoded.first_name));
                dispatch(checkSecurityQuestion(decoded.username))
            })
            .catch(err=>{
                dispatch(authFail(err.message));
            })
    };


};

export const checkSecurityQuestion = (username) => {
    return dispatch => {
        requestSecurityQuestions(username)
            .then(response=> {
                if (response.data.length>0)
                    {
                        dispatch(setupSuccess());
                        return null
                    }
                else
                    {
                        return requestAllSecurityQuestions();
                    }
            })
            .then(response => {
                if (response===null)
                    return;
                else{
                    dispatch(setupStart());
                    dispatch(allQuestions(response.data))
                }
            })
            .catch(err=>{
                dispatch(logout());
            })
    };
};

export const setSecurityQuestionAnswers = (questions, answers) => {
    return dispatch => {
        requestSetSecurityQuestions(questions, answers)
            .then(response=> {
                dispatch(setupSuccess());
            })
    };
};

export const allQuestions = (questions) => {
    return {
        type: types.SETUP_ALL_QUESTIONS,
        questions: questions
    };
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime);
    };
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem(key_token);
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = localStorage.getItem(key_expiration_date);
            if(expirationDate > new Date().getTime()){
                const decoded = jwt(token);
                const firstName = decoded.firstName;
                dispatch(authSuccess(token, firstName));
                dispatch(checkAuthTimeout(expirationDate - new Date().getTime()));
                dispatch(checkSecurityQuestion(decoded.username))
            }
            else{
                dispatch(logout())
            }
        }
    };
};
