export const Colors = {
    loginColorArr: ['#16A950', '#13a04c', '#0c8742', '#016031'],
    themeColor: '#16A850',
    greyBackgroundColor: '#F6F7FA',

    black: '#000000',
    white: '#FFFFFF',
    grey: '#E2E8EE',
    darkGrey: '#9d9d9d',
    red: '#FF7A9A',
    green: '#81D0A0',
    lightGreen: '#A8D126',

}
