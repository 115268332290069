import moment from "moment";
import {DAY_FILTER_KEY, WEEK_FILTER_KEY} from "./graphTimeFilterTabs";

const samplePieData = {
    labels: ['Carbohydrate', 'Protein', 'Fat'],
    datasets: [
        {
            data: [40,20,5]
        }
    ]
};

const samplePatientTableData = [
    {
        "patient": {
            "first_name": "Jane",
            "age": 30,
            "username": "janez",
            "gender": "female",
            "trial_id": "12345"
        },
        "patient_type": {
            "newly_started_insulin": true,
            "steroid": 1,
            "pregnancy": true,
            "priority": false,
            "blevel_risk": 0,
            "diabetes_type": 2,
            "allergies": [
                "nuts"
            ]
        }
    },
    {
        "patient": {
            "first_name": "Jimmy",
            "age": 40,
            "username": "gymmy",
            "gender": "male",
            "trial_id": "12346"
        },
        "patient_type": {
            "newly_started_insulin": false,
            "steroid": 0,
            "pregnancy": false,
            "priority": true,
            "blevel_risk": 1,
            "diabetes_type": 1,
            "allergies": [
                "ice-cream"
            ]
        }
    }

];


function getSampleData(filterKey) {
    let s = null;
    if (filterKey === DAY_FILTER_KEY) {
         s = {
            labels: ["14/08/2020 06:00:00", "14/08/2020 09:30:00", "14/08/2020 12:00:00", "14/08/2020 16:00:00", "14/08/2020 18:00:00", "14/08/2020 23:20:00"],
            datasets: [
                {
                    label: '',
                    data: [1800, 2400, 1900, 2200, 2100, 1950]
                },
            ],
        };
    } else if (filterKey === WEEK_FILTER_KEY) {
        s = {
            labels: ["14/08/2020 00:00:00", "15/08/2020 00:00:00", "16/08/2020 00:00:00", "17/08/2020 00:00:00", "18/08/2020 00:00:00", "19/08/2020 00:00:00"],
            datasets: [
                {
                    label: '',
                    data: [1800, 2400, 1900, 2200, 2100, 1950]
                },
            ],
        };
    } else {
        const labels = [];
        const data = [];

        let now = moment(new Date()).startOf('day').subtract(28, 'days');
        let prevValue = 2000;
        const incrAmount = 50;
        const probIncr = 0.4
        for (let i = 0; i < 28; i++) {
            labels.push(now.format("DD/MM/YYYY HH:mm:ss"));
            data.push(prevValue); // push null to the dataset if there is no data.

            const isIncrease = Math.random() <= probIncr;

            if (isIncrease) {
                prevValue += incrAmount
            } else {
                prevValue -= incrAmount
            }

            now.add(1, 'day');
        }

        s = {
            labels: labels,
            datasets: [
                {
                    label: '',
                    data: data
                },
            ],
        };
    }
    s = JSON.stringify(s);
    const dup = JSON.parse(s);

    dup.labels = dup.labels.map(label => moment(label, "DD/MM/YYYY HH:mm:ss").toDate());
    return dup;
}

const sampleFoodTableData = [
    {
        food_intake_log: "Morning",
        time: "08:00am",
        serving: 1,
        carbohydrate: 256,
        protein: 200,
        fat: 100,
        calorie: 1200,
        food_items: [
            {food_name: "Milo", food_intake_log: "Milozzzzzzzz", time: "08:00am",serving: 1, img: "https://d1nqx6es26drid.cloudfront.net/app/uploads/2015/04/04035541/product-hot-milo.png", carbohydrate: 256, protein: 200, fat: 100, calorie: 1200}
        ]
    },
    {
        food_intake_log: "Afternoon",
        time: "12:30pm",
        serving: 1,
        carbohydrate: 256,
        protein: 200,
        fat: 100,
        calorie: 1200,
        food_items: [
            {food_name: "Milo", food_intake_log: "Milozzzzzzzz", time: "08:00am",serving: 1, img: "https://d1nqx6es26drid.cloudfront.net/app/uploads/2015/04/04035541/product-hot-milo.png", carbohydrate: 256, protein: 200, fat: 100, calorie: 1200}
        ]
    },
    {
        food_intake_log: "Evening",
        time: "07:00pm",
        serving: 1,
        carbohydrate: 256,
        protein: 200,
        fat: 100,
        calorie: 1200,
        food_items: [
            {food_name: "Milo", food_intake_log: "Milozzzzzzzz", time: "08:00am",serving: 1, img: "https://d1nqx6es26drid.cloudfront.net/app/uploads/2015/04/04035541/product-hot-milo.png", carbohydrate: 256, protein: 200, fat: 100, calorie: 1200}
        ]
    },
    {
        food_intake_log: "Night",
        time: "11:30pm",
        serving: 1,
        carbohydrate: 256,
        protein: 200,
        fat: 100,
        calorie: 1200,
        food_items: [
            {food_name: "Milo", food_intake_log: "Milozzzzzzzz", time: "08:00am",serving: 1, img: "https://d1nqx6es26drid.cloudfront.net/app/uploads/2015/04/04035541/product-hot-milo.png", carbohydrate: 256, protein: 200, fat: 100, calorie: 1200}
        ]
    }
]

export {samplePieData, getSampleData, sampleFoodTableData, samplePatientTableData};
