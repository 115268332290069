import React from "react";
import {Box, CardMedia, GridList, GridListTile, ButtonBase} from "@material-ui/core";
import CalendarCell from "./calendarCell";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";
import {getMonthStr, generateDayInWeekList} from "../../../commonFuncs/calendarFuncs";


const CalendarComponent = props => {
    const classes = useStyles();

    const {selectedDate, shownMonthDate, calendarList, prevMonth, nextMonth, handleCalendarDateSelect} = props;
    const weekdayList = generateDayInWeekList();

    const selectedDayNum = () => {
        if(selectedDate.getFullYear() === shownMonthDate.getFullYear() && selectedDate.getMonth() === shownMonthDate.getMonth()){
            return selectedDate.getDate();
        }
        return '';
    }

    return (
            <Box className={classes.container} border={1} boxShadow={2} >
                <Box className={classes.monthLayerContainer}>
                    <ButtonBase onClick={() => {prevMonth()}}>
                        <CardMedia className={classes.arrowIcon} component="img" src="/images/main/svg/hcw-icon-grey-chevron-left.svg"/>
                    </ButtonBase>
                    <Box className={classes.monthText} component={'b'}>{getMonthStr(shownMonthDate.getMonth())} {shownMonthDate.getFullYear()}</Box>
                    <ButtonBase onClick={() => {nextMonth()}}>
                        <CardMedia className={classes.arrowIcon} component="img" src="/images/main/svg/hcw-icon-grey-chevron-right.svg"/>
                    </ButtonBase>
                </Box>
                <GridList className={classes.calendarGrid} cellHeight={50} cellWidth={50} cols={7} spacing={3}>
                    {weekdayList.map((item, index) => {
                        return <GridListTile key={index} cols={1}>
                            <Box className={classes.titleContainer} component={'b'}>{item}</Box>
                        </GridListTile>
                    })}
                    {calendarList.map((item, index) => {
                           return <GridListTile key={index} cols={1}>
                               <ButtonBase onClick={() => {handleCalendarDateSelect(item)}}>
                                    <CalendarCell selectedDayNum={selectedDayNum()} cellInfo={item}/>
                               </ButtonBase>
                            </GridListTile>
                    })}
                </GridList>
            </Box>
    )
}

export default CalendarComponent;

const useStyles = makeStyles({
    container :{
        borderRadius: 10,
        borderColor: Colors.grey,
        backgroundColor: Colors.white,
        display: 'inline-block',
        flexDirection: 'column',
        width:'auto',
        height:500,
        padding:20,
    },
    calendarGrid: {
        width:370,
    },
    monthLayerContainer:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems:'center',
        marginTop:10,
        marginBottom:10,
    },
    titleContainer:{
        display: 'flex',
        justifyContent: 'center',
        alignItems:'center',
        marginTop:10,
        marginBottom:10,
    },
    monthText: {
        fontSize: 24,
    },
    arrowIcon: {
        width: 30,
        height: 30,
        aspectRatio:1,
        objectFit: 'contain',
    },
})
