import React, {useState} from 'react';
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import TopBarWithBtns from "../components/main/topBarWithBtns";
import LeftNavigator from "../components/main/leftNavigator";
import MainContent from "../components/main/mainContent";


const Dashboard = props => {
    const classes = useStyles();

    const { match, history } = props;
    const { params } = match;
    const { page } = params;

    const tabToIndex = {
        0: 'home',
        1: 'patientInfo',
        2: 'appointments',
        3: 'setGoal',
        4: 'chat',
        5: 'account'
    }

    const indexToTab = {
        home: 0,
        patientInfo: 1,
        appointments: 2,
        setGoal: 3,
        chat: 4,
    }

    const [selectedTab, setSelectedTab] = useState(indexToTab[page]);
    // const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (newSelectTab) => {
        history.push(`/${tabToIndex[newSelectTab]}`);
        setSelectedTab(newSelectTab);
    };

    return(
        <>
            <TopBarWithBtns className={classes.topBar} selectedTab={selectedTab} handleTabChange={handleTabChange} />
            <Box component="span" className={classes.subContainer}>
                <LeftNavigator className={classes.leftNav} selectedTab={selectedTab} handleTabChange={handleTabChange}/>
                <MainContent className={classes.mainContent} selectedTab={selectedTab} />
            </Box>
        </>
    );
}

const useStyles = makeStyles((theme) => ({
    subContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '93vh',
    },
    topBar: {
        height: '7vh',
        minHeight: 70,
    },
    leftNav: {
        [theme.breakpoints.up('xs')]: {
            width: 0,
        },
        [theme.breakpoints.up('sm')]: {
            width: 0,
        },
        [theme.breakpoints.up('md')]: {
            width: 0,
        },
        [theme.breakpoints.up('lg')]: {
            width: '5%',
            minWidth: 70,
        },
        [theme.breakpoints.up('xl')]: {
            width: '5%',
            minWidth: 70,
        },

    },
    mainContent: {
        [theme.breakpoints.up('xs')]: {
            width: '100%',
            height: '93vh',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
            height: '93vh',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
            height: '93vh',
        },
        [theme.breakpoints.up('lg')]: {
            width: '95%',
            minWidth: 'calc(100% - 70)',
            height: '93vh',
        },
        [theme.breakpoints.up('xl')]: {
            width: '95%',
            minWidth: 'calc(100% - 70)',
            height: '93vh',
        },
    },
}))

export default Dashboard;
