import {Box, Button, TextField} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import React, {useState} from "react";
import axios from "axios";
import Link from "@material-ui/core/Link";
import Snackbar from "@material-ui/core/Snackbar";
import {makeStyles} from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";


const LoginForm = props => {
    const classes = useStyles();

    const [error, setError] = useState("");

    const checkInput = () => {
        if (!username){
            setError("Username cannot be empty.");
            return false;
        }
        if (!password){
            setError("Password cannot be empty.");
            return false;
        }
        setError('');
        return true;
    };

    const {initForgot, passwordResetSuccess, handleClose, username, setUsername, password, setPassword, login} = props;

    return (
        <>
            <Box component='form' className={classes.formWithBottom} onSubmit={(e) => {
                e.preventDefault();
                if (checkInput())
                    login(username, password, true)
            }}>
                <Box style={{width: '60%', fontSize: 40}} component='b' color='white'>Welcome</Box>
                <Box style={{marginTop: 20, width: '60%', fontSize: 20}} component='sub' color='white'>To proceed with the portal, please log in with your credential.</Box>
                <TextField
                    style={{marginTop: 20, width: '60%', height: 60}}
                    label="Username"
                    variant="outlined"
                    value={username}
                    error={props.loginError}
                    onChange={(e) => {setUsername(e.target.value)}}
                />
                <TextField
                    style={{marginTop: 20, width: '60%', height: 60}}
                    label="Password"
                    variant="outlined"
                    type="password"
                    value={password}
                    error={props.loginError}
                    onChange={(e) => {setPassword(e.target.value)}}
                />
                {!!error? <Alert  style={{marginTop: 20, width: '60%'}} severity="error">{error}</Alert>: null}
                {props.loginError? <Alert  style={{marginTop: 20, width: '60%'}} severity="error">Wrong username/password combination.</Alert>: null}
                <Button style={{marginTop: 20, width: '60%', height: 60, backgroundColor: '#f5f6f9',
                    borderColor: '#f5f6f9'}} variant="contained" color="white" type="submit">
                    <b>Log In</b>
                </Button>
                <Link style={{marginTop: 20,  fontSize: 20}} component='button' color='white' href="#" onClick={initForgot}>Forget Password?</Link>

                <Snackbar open={passwordResetSuccess} autoHideDuration={6000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success">
                        Password successfully reset! You can now login with your new password.
                    </Alert>
                </Snackbar>
            </Box>

            <Box className={classes.bottomLayer} color='white'>Having trouble? <Box component='a' color='white' href="mailto:sg8empower@gmail.com"><b>Contact Us Now</b></Box></Box>
        </>
    )
}

const ForgotPasswordForm = props => {
    const classes = useStyles();

    const [error, setError] = useState("");

    const {setSequence, username, setUsername, setQuestions} = props;

    const getForgotQuestions = (username) => {
        let url = `/auth/physician-security-questions/${username}`;
        axios.get(url)
            .then(response=> {
                const moddedQuestions = response.data.map(x => {
                    x['answer'] = '';
                    return x;
                });
                setQuestions(moddedQuestions);
                setSequence(2)
            })
            .catch(err=>{
                setError(err.response.data.message);
            })
    };

    return(
            <Box component='form' className={classes.formWithoutBottom} onClick={(e) => {
                e.preventDefault();
                getForgotQuestions(username) }}>
                <Box style={{width: '60%', fontSize: 40}} component='b' color='white'>Forgot Password</Box>
                <Box style={{marginTop: 20, width: '60%', fontSize: 20}} component='sub' color='white'>To change your password, please provide us with your Username.</Box>
                <TextField
                    style={{marginTop: 20, width: '60%', height: 60}}
                    label="Username"
                    variant="outlined"
                    value={username}
                    onChange={(e) => {setUsername(e.target.value)}}
                />
                {error && <Alert  style={{marginTop: 20, width: '60%'}} severity="error">{error}</Alert>}
                <Button style={{marginTop: 20, width: '60%', height: 60}} variant="contained" color="white" type='submit'>
                    Next
                </Button>
            </Box>
        )
};


const AnswerQuestionsForm = props => {
    const {questions, username, token, setSequence} = props;
    const classes = useStyles();
    const [error, setError] = useState('');
    const [answer, setAnswer] = useState('');
    const [question, setQuestion] = useState('');

    const verifyAnswers = (question, answer) => {
        let url = `/auth/security-answers/verify/physician`;
        const authData = {
            username: username,
            answers: [{"question_id": question._id, "answer": answer}],
        };
        axios.post(url, authData)
            .then(response=> {
                token.current = response.data.token;
                setSequence(3)
            })
            .catch(err=>{
                setError(err.response.data.message);
            })
    };

    const handleSelectChange = (e) => {
        setQuestion(e.target.value);
    };

    return <Box component='form' className={classes.formWithoutBottom} onSubmit={(e) => {
        e.preventDefault();
        verifyAnswers(question, answer) }}>
        <Box style={{width: '60%', fontSize: 40}} component='b' color='white'>Verification</Box>
        <Box style={{marginTop: 20, width: '60%', fontSize: 20}} component='sub' color='white'>To help us verify your identity, please answer one of your security questions.</Box>
        <FormControl className={classes.formControl}>
            <InputLabel id="security-question-select-label">Security Questions</InputLabel>
            <Select
                labelId="security-question-select-label"
                id="simple-select"
                value={question}
                onChange={handleSelectChange}
            >
                {
                    questions.map((question, index) => {
                        return (<MenuItem value={question}>{question.content}</MenuItem>);
                    })
                }
            </Select>
        </FormControl>
        <TextField
            style={{marginTop: 20, width: '60%', height: 60}}
            label="Answer"
            variant="outlined"
            value={answer}
            error={!!error}
            onChange={(e) => {
                setAnswer(e.target.value)}
            }
        />
        {error? <Alert  style={{marginTop: 20, width: '60%'}} severity="error">{error}</Alert>: null}
        <Button style={{marginTop: 20, width: '60%', height: 60}} variant="contained" color="white" type='submit'>
            Next
        </Button>
    </Box>
};


const ChangePasswordForm = ({changePassword}) => {
    const classes = useStyles();
    const [newPassword, setNewPassword] = useState('');
    const [confirmPass, setConfirmPass] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const checkPassword = () => {
        let error = '';
        if(newPassword !== confirmPass) {
            error = 'The two passwords you typed in are not the same.';
        }
        if(newPassword.length < 6) {
            error = 'The password must be at least 6 characters long.';
        }
        if (error){
            setErrorMessage(error);
            return false
        }
        return true
    };

    return <Box component='form' className={classes.formWithoutBottom}
                 onSubmit={(e) => {
                     e.preventDefault();
                     if (checkPassword())
                         changePassword(newPassword)
                 }}>
        <Box style={{width: '60%', fontSize: 40}} component='b' color='white'>Change Password</Box>
        <Box style={{marginTop: 20, width: '60%', fontSize: 20}} component='sub' color='white'>Verification is successful. Please enter the password that you wish to change to.</Box>
        <TextField
            style={{marginTop: 20, width: '60%', height: 60}}
            label="Password"
            variant="outlined"
            value={newPassword}
            type="password"
            error={!!errorMessage}
            onChange={(e) => {setNewPassword(e.target.value)}}
        />
        <TextField
            style={{marginTop: 20, width: '60%', height: 60}}
            label="Confirm Password"
            variant="outlined"
            value={confirmPass}
            type="password"
            error={!!errorMessage}
            onChange={(e) => {setConfirmPass(e.target.value)}}
        />
        {errorMessage && <Alert  style={{marginTop: 20, width: '60%'}} severity="error">{errorMessage}</Alert>}
        <Button style={{marginTop: 20, width: '60%', height: 60}} variant="contained" color="white" type='submit'>
            Change Password
        </Button>
    </Box>
};

const useStyles = makeStyles({
    formWithBottom: {
        height: '90%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomLayer: {
        fontSize: 20,
        bottom: 20,
        height: '10%',
    },
    formWithoutBottom: {
        height: '100%',
        flexDirection: 'column',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    formControl: {marginTop: 20, width: '60%'}
});

export {LoginForm, ForgotPasswordForm, AnswerQuestionsForm, ChangePasswordForm}
