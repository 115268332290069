import axiosInstance from "../util/axios";


const baseRequestGet = async (url, logName, params) => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(url, params).then(response=> {
            resolve(response);
        }).catch(error =>{
            console.log('Request Error - ' + logName + ' : ' + error.message);
            reject(error);
        })
    })
}

const baseRequestPost = (url, logName, params) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(url, params)
            .then(response=> {
                resolve(response);
            })
            .catch(error =>{
                console.log('Request Error - ' + logName + ' : ' + error.message);
                reject(error);
            })
    })
}

const baseRequestDelete = (url, logName, params) => {
    return new Promise((resolve, reject) => {
        axiosInstance.delete(url, params).then(response=> {
            resolve(response);
        }).catch(error =>{
            console.log('Request Error - ' + logName + ' : ' + error.message);
            reject(error);
        })
    })
}

export {baseRequestGet, baseRequestPost, baseRequestDelete}
