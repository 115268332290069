import React, {useEffect, useState} from 'react';
import {Box, Breadcrumbs, Link, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../styles/Colors";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import EditPasswordDialog from "../components/Account/EditPasswordDialog";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import Divider from "@material-ui/core/Divider";
import EditNameDialog from "../components/Account/EditNameDialog";
import SecurityQuestionSetupDialog from "../components/Account/SetupSecurityQuestions";
import {connect} from "react-redux";
import {mapStateToProps} from "../redux/reduxMapping";
import {
    requestAllSecurityQuestions,
    requestSecurityQuestions,
    requestSetSecurityQuestions
} from "../netCalls/requestsLoginModule";
import {requestGetProfile} from "../netCalls/requestAccountModule";
import {allQuestions, setSecurityQuestionAnswers, setupSuccess} from "../redux/actions";


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: Colors.grey,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: 20,
    },
    mainTitle:{
        fontSize: 30,
        marginTop: 10,
        marginBottom: 20,
    },
    formWithBottom: {
        flexDirection: 'column',
        display: 'flex',
        backgroundColor: Colors.white,
        borderRadius: 10,
        padding: 20,
        margin: 20
    }
}));

const AccountView = (props) => {
    const classes = useStyles();
    const { allQuestions, setAllQuestions } = props;
    const [passwordDialog, setPasswordDialog] = useState(false);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const [nameDialog, setNameDialog] = useState(false);
    const [nameResetSuccess, setNameResetSuccess] = useState(false);
    const [questionDialog, setQuestionDialog] = useState(false);
    const [questionResetSuccess, setQuestionResetSuccess] = useState(false);
    // display data
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [title, setTitle] = useState('');
    const [specialty, setSpecialty] = useState('');
    const [subSpecialties, setSubSpecialties] = useState('');
    const [education, setEducation] = useState('');
    const [questionsUpdatedAt, setQuestionsUpdatedAt] = useState('');

    useEffect(() => {
        requestGetProfile()
            .then(response=> {
                const data = response.data;
                setUsername(data.username);
                setName(data.first_name);
                setPassword(data.password);
                setTitle(data.profile.title);
                setSpecialty(data.profile.specialty);
                setSubSpecialties(data.profile.sub_specialties);
                setEducation(data.profile.education);
            });
        requestSecurityQuestions(username)
            .then(response => {
                setQuestionsUpdatedAt(`Last Updated ${response.data[0].updated_at}`)
            });
        requestAllSecurityQuestions()
            .then(response => {
                setAllQuestions(response.data);
            })
    }, [passwordResetSuccess, nameResetSuccess, questionResetSuccess]);

    const handlePasswordReset = e => {
      setPasswordDialog(true);
    };

    const handleNameReset = e => {
        setNameDialog(true);
    };

    const handleQuestionReset = e => {
        setQuestionDialog(true);
    };

    const handleSnackbarClose = e => {
        setPasswordResetSuccess(false);
        setNameResetSuccess(false);
        setQuestionResetSuccess(false);
    };

    const updateSecurityQuestionAnswers = (questions, answers)=>{
        requestSetSecurityQuestions(questions, answers)
            .then(response=> {
                setQuestionDialog(false);
                setQuestionResetSuccess(true);
            })
    };

    return <Box className={classes.container}>
        <Breadcrumbs separator={'>'}>
            <Link color="inherit" href="home"  >
                <b>Home</b>
            </Link>
            <Link color="inherit" >
                <b>View Account</b>
            </Link>
        </Breadcrumbs>

        <Box className={classes.mainTitle} component={'b'}>View Account</Box>

        <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                <Box className={classes.formWithBottom}>
                    <Box component='flex' flexDirection='row' p={1} style={{
                        display: "flex",
                        alignItems:'center',
                        padding: 0
                    }}><Icon>
                            <img style={{height: 'inherit',
                            width: 'inherit'}} alt="AccountImage" src="/images/main/svg/hcw-icon-grey-account.svg"/>
                        </Icon>
                        <Box p={1} flexGrow={1}>Account Details</Box>
                        </Box>
                    <Box component='flex' flexDirection='row' p={1} style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems:'center',
                        padding: 0
                    }}>
                        <Box p={1} flexGrow={1} style={{ padding: 0 }}>
                            <TextField
                                label="Username"
                                value={username}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Box>
                    </Box>
                    <Divider/>
                    <Box component='flex' flexDirection='row' p={1} style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems:'center',
                        padding: 0
                    }}>
                        <Box p={1} flexGrow={1} style={{ padding: 0 }}>
                            <TextField
                                label="Name"
                                value={name}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Box>
                        <IconButton onClick={handleNameReset}>
                            <Icon>
                                <img style={{height: 'inherit',
                                    width: 'inherit'}} alt="AccountImage" src="/images/main/svg/hcw-icon-lightgreen-edit.svg"/>
                            </Icon>
                        </IconButton>
                    </Box>
                    <Divider/>
                    <Box component='flex' flexDirection='row' p={1} style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems:'center',
                        padding: 0
                    }}>
                        <Box p={1} flexGrow={1} style={{ padding: 0 }}>
                            <TextField
                                style={{ width: '100%' }}
                                label="Security Questions"
                                value={questionsUpdatedAt}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Box>
                        <IconButton onClick={handleQuestionReset}>
                            <Icon>
                                <img style={{height: 'inherit',
                                    width: 'inherit'}} alt="AccountImage" src="/images/main/svg/hcw-icon-lightgreen-edit.svg"/>
                            </Icon>
                        </IconButton>
                    </Box>
                    <Divider/>
                    <Box component='flex' flexDirection='row' p={1} style={{
                        marginTop: 20,
                        display: "flex",
                        alignItems:'center',
                        padding: 0
                    }}>
                        <Box p={1} flexGrow={1} style={{ padding: 0 }}>
                            <TextField
                                label="Password"
                                type="password"
                                value={password}
                                InputProps={{
                                    readOnly: true,
                                    disableUnderline: true
                                }}
                            />
                        </Box>
                        <IconButton onClick={handlePasswordReset}>
                            <Icon>
                                <img style={{height: 'inherit',
                                    width: 'inherit'}} alt="AccountImage" src="/images/main/svg/hcw-icon-lightgreen-edit.svg"/>
                            </Icon>
                        </IconButton>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
                <Box className={classes.formWithBottom}>
                    <Box component='flex' flexDirection='row' style={{
                        display: "flex",
                        alignItems:'center'
                    }}><Icon>
                        <img style={{height: 'inherit',
                            width: 'inherit'}} alt="AccountImage" src="/images/main/svg/hcw-icon-grey-account.svg"/>
                    </Icon><Box p={1} flexGrow={1}>Professional Profile</Box></Box>
                    <TextField
                        style={{marginTop: 20, height: 60}}
                        label="Title"
                        value={title}
                    />
                    <TextField
                        style={{marginTop: 20, height: 60}}
                        label="Specialty"
                        value={specialty}
                    />
                    <TextField
                        style={{marginTop: 20, height: 60}}
                        label="Sub-Specialties"
                        value={subSpecialties}
                    />
                    <TextField
                        style={{marginTop: 20, height: 60}}
                        label="Education"
                        value={education}
                    />
                </Box>
            </Grid>
        </Grid>


        <EditPasswordDialog isOpen={passwordDialog} setIsOpen={setPasswordDialog} classes={classes}
                            setSuccessMessage={setPasswordResetSuccess}/>
        <EditNameDialog isOpen={nameDialog} setIsOpen={setNameDialog} classes={classes}
                            setSuccessMessage={setNameResetSuccess}/>
        <SecurityQuestionSetupDialog submit={updateSecurityQuestionAnswers} open={questionDialog}
                                     allQuestions={allQuestions} cancel={()=>{setQuestionDialog(false)}}
                                     cancelText="Cancel" submitText={"Save"}/>
        <Snackbar open={passwordResetSuccess} autoHideDuration={6000} onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleSnackbarClose} severity="success">
                Password successfully reset!
            </Alert>
        </Snackbar>
        <Snackbar open={nameResetSuccess} autoHideDuration={6000} onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleSnackbarClose} severity="success">
                Name successfully reset!
            </Alert>
        </Snackbar>
        <Snackbar open={questionResetSuccess} autoHideDuration={6000} onClose={handleSnackbarClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleSnackbarClose} severity="success">
                Security questions successfully reset!
            </Alert>
        </Snackbar>
    </Box>
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAllQuestions: data => dispatch(allQuestions(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);



