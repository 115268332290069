import { defaults } from 'react-chartjs-2';
import moment from 'moment';
// graph plugins
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-annotation';
import {DAY_FILTER_KEY} from "./graphTimeFilterTabs";
// Add all settings and config in this file

const graphDateFormats = {
    'inter-day': "DD/MM",
    'intra-day': "HH:mm",
}

const graphXAxesScale = {
    Day: {
        unit: 'hours',
        stepSize: 6,
        tooltipFormat: 'HH:mm'
    },
    Week: {
        unit: 'day',
        stepSize: 1,
        tooltipFormat: 'DD MMM'
    },
    Month: {
        unit: 'day',
        stepSize: 7,
        tooltipFormat: 'DD MMM'
    }
}

// same color scheme from export report
const graphColorScheme = {
    axis_colour: '#cdcdcd',
    axis_label_colour: '#E1E7ED',
    axis_text_label_colour: '#3c3c43',
    axis_point_color: '#aad326',
    boundary_color: 'rgba(0,0,0,0.1)',
    danger_color: 'red',
    hover_color: '#16A950'
}

const barGraphStyles = {
    xFontSize: 12,
    barWidthFrac: {
        'Day': 0.2,
        'Week': 0.2,
        'Month': 0.5
    }
};

function getBarGraphOptions(filterKey, suggestedMin, suggestedMax) {
    return {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        callback: formatY,
                        suggestedMin,
                        suggestedMax
                    },
                },
            ],
            xAxes: [
                {
                    type: 'time',
                    time: graphXAxesScale[filterKey],
                    ticks: {
                        fontColor: graphColorScheme.axis_text_label_colour,
                        fontSize: barGraphStyles.xFontSize,
                        callback: (x) => moment(x).format(graphDateFormats[filterKey === DAY_FILTER_KEY ? "intra-day" : "inter-day"])
                    },
                    offset: true, // to pad space between origin and first x axis value
                    gridLines: {
                        display: false
                    },
                    barPercentage: barGraphStyles.barWidthFrac[filterKey]
                }
            ]
        },
        tooltips: {
            intersect: false,
            mode: 'x',
            //enabled: false
        },
        hover: {
            enabled: true
        },
        events: ['mousemove'],
        animation: {
            duration: 0,
            animateRotate: false
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        layout: {
            padding: {
                top: 18
            }
        },
        elements: {
            line: {
                tension: 0
            }
        }
    };
}


// line graph styles
const lineGraphStyles = {
    cursorLineWidth: 1.1,
    lineColor: '#aad326',
    cursorRadius: 4.0,
    cursorColor: '#aad326',
    lineWidth: 1.0,
    pointRadius: 1.0,
    yFontSize: 16,
    xFontSize: 12,
    pointHitRadius: 2.4, // determines the detection area
};

function getLineGraphOptions(filterKey, suggestedMin, suggestedMax) {
    return  {
        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: graphColorScheme.axis_text_label_colour,
                        fontSize: lineGraphStyles.yFontSize,
                        callback: formatY,
                        suggestedMin,
                        suggestedMax
                    },
                },
            ],
            xAxes: [
                {
                    type: 'time',
                    time: graphXAxesScale[filterKey],
                    ticks: {
                        fontColor: graphColorScheme.axis_text_label_colour,
                        fontSize: lineGraphStyles.xFontSize,
                        callback: (x) => moment(x).format(graphDateFormats[filterKey === DAY_FILTER_KEY ? "intra-day" : "inter-day"])
                    },
                    offset: true, // to pad space between origin and first x axis value
                    gridLines: {
                        display: false
                    }
                }
            ]
        },
        tooltips: {
            intersect: false,
            mode: 'x',
            bodyAlign: 'center',
            xPadding: 10
            //custom: customTooltip,
            //enabled: false
        },
        hover: {
            enabled: true
        },
        events: ['mousemove'],
        animation: {
            duration: 0,
            animateRotate: false
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        layout: {
            padding: {
                top: 18
            }
        },
        elements: {
            line: {
                tension: 0
            }
        }
    };
}

function formatY(y) {
    if (y < 1000) {
        return y;
    } else if (y < 1000000) {
        return (y / 1000).toFixed(1) + ' K';
    } else {
        return (y / 1000000).toFixed(1) + ' M';
    }
}

export {getLineGraphOptions, getBarGraphOptions, graphDateFormats, graphColorScheme, lineGraphStyles, barGraphStyles};
