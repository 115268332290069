import React, {useEffect} from 'react';
import LoginPage from "./screens/LoginPage";
import Dashboard from "./screens/Dashboard";
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import {mapDispatchToProps, mapStateToProps} from './redux/reduxMapping';
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const App = (props) => {
    useEffect(() => {
        props.onTryAutoSignup();
    });
  const classes = useStyles();
  return (
      <Box component="span" className={classes.container}>
          <BrowserRouter>
              {props.isLogin ? (
                      <Switch>
                          <Redirect exact from={'/'} to={'/home'} />
                          <Route path='/:page?' exact render={props => <Dashboard {...props}/>} />
                      </Switch>
                  ) : (
                    <LoginPage />
                  )
              }
          </BrowserRouter>
      </Box>
  )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        [theme.breakpoints.up('xs')]: {
            width: '100vw',
            minWidth: 600,
        },
        [theme.breakpoints.up('sm')]: {
            width: '100vw',
            minWidth: 600,
        },
        [theme.breakpoints.up('md')]: {
            width: '100vw',
            midWidth: 960,
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '5vw',
            paddingRight: '5vw',
            width: '90vw',
            minWidth: 960,
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '10vw',
            paddingRight: '10vw',
            width: '80vw',
            minWidth: '1280px',
        }
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(App);
