import * as types from '../constants';
import {updateObject} from "../helpers";


const initialState = {
    token: null,
    firstName: null,
    error: null,
    additionalChecking: true,
    setup: false,
    allQuestions: []
};

const authStart = (state, action) => {
    return updateObject(state, { error: null, additionalChecking: true});
};

const authSuccess = (state, action) => {
    return updateObject(state,
        {
            token:action.token,
            firstName:action.firstName,
            error:null
        });
};

const authFail = (state, action) => {
    return updateObject(state, {
        error: action.error
    });
};

const authLogout = (state, action) => {
    return updateObject(state, { token: null, firstName: null,
        setup: false, additionalChecking: false });
};

const setupStart = (state, action) => {
    return updateObject(state, { setup: true });
};

const setupSuccess = (state, action) => {
    return updateObject(state, { setup: false, additionalChecking: false });
};

const setupAllQuestions = (state, action)=>{
    return updateObject(state,
        {
            allQuestions: action.questions
        })
};

const reducer = (state=initialState, action) => {
    switch(action.type){
        case types.AUTH_START:
            return authStart(state, action);
        case types.AUTH_SUCCESS:
            return authSuccess(state, action);
        case types.AUTH_FAIL:
            return authFail(state, action);
        case types.AUTH_LOGOUT:
            return authLogout(state, action);
        case types.SETUP_START:
            return setupStart(state, action);
        case types.SETUP_SUCCESS:
            return setupSuccess(state, action);
        case types.SETUP_ALL_QUESTIONS:
            return setupAllQuestions(state, action);
        default:
            return state;
    }
};

export default reducer;