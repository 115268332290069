import React, {Fragment, useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import {Box, Button, TextField} from "@material-ui/core";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../../redux/reduxMapping";
import axiosInstance from "../../util/axios";
import Alert from "@material-ui/lab/Alert";
import {withStyles} from "@material-ui/core/styles";
import {requestUpdateProfile} from "../../netCalls/requestAccountModule";

const BootstrapButton = withStyles({
    root: {
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        padding: '6px 32px',
        width: '30%',
        height: 55,
        lineHeight: 1.5,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),

    },
})(Button);

const EditNameDialog = (props) => {
    const {isOpen, setIsOpen, classes, setSuccessMessage} = props;
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const changeName = (newName) => {
        requestUpdateProfile({name: newName})
            .then(
                response => {
                    setIsOpen(false);
                    setSuccessMessage(true);
                }
            )
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        changeName(name);
    };

    return  <Dialog aria-labelledby="customized-dialog-title" open={isOpen}
                    disableBackdropClick={true} disableEscapeKeyDown={true}>
        <DialogTitle id="customized-dialog-title" style={{paddingBottom: 0}}>
            <p style={{fontSize:"32px", margin: 0}}><b>Edit</b></p>
            <p style={{margin: 0}}><b>First Name</b></p>
        </DialogTitle>
        <DialogContent style={{paddingTop: 0}}>
            <Box component='form' className={classes.formWithoutBottom}
                 onSubmit={handleSubmit}>
                <TextField
                    style={{marginTop: 20, width: '60%', height: 60}}
                    label="First Name"
                    variant="outlined"
                    value={name}
                    error={!!errorMessage}
                    onChange={(e) => {setName(e.target.value)}}
                />
                {errorMessage && <Alert  style={{marginTop: 20, width: '60%'}} severity="error">{errorMessage}</Alert>}
                <Box display="flex"  alignItems="center"
                     justifyContent="center">
                    <BootstrapButton style={{margin: 20}} variant="contained" onClick={(e)=>setIsOpen(false)}>
                        <b>Cancel</b>
                    </BootstrapButton>
                    <BootstrapButton style={{margin: 20, backgroundColor: '#16a950', color: 'white',
                        borderColor: '#16a950'}} variant="contained" type='submit'>
                        <b>Save</b>
                    </BootstrapButton>
                </Box>
            </Box>
        </DialogContent>

    </Dialog>

};


export default connect(mapStateToProps, mapDispatchToProps)(EditNameDialog);