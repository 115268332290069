import React from 'react';
import {PatientProfileCard} from "../../components/main/patientInfo/patientProfileCard";
import { makeStyles } from '@material-ui/core/styles';
import {AvatarWithText} from "../../components/main/avatarWithText";
import {samplePatientTableData} from "../../components/main/patientInfo/patientTable";
import Box from '@material-ui/core/Box';
import {AntTab, AntTabs} from "../../components/main/tabs";
import { useLocation, useHistory } from 'react-router-dom';
import {
    ActivityGraphCard,
    BloodGlucoseGraphCard,
    FoodIntakeGraphCard,
    WeightGraphCard
} from "../../components/graphs/graphCard";
const qs = require('qs');

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        width: '99%',
    },
    content: {
        marginTop: '3%',
    },
    tabs: {

    },
}));

const tabs = [
    {id: 0, label: 'Info'},
    {id: 1, label: 'Reports'},
    {id: 2, label: 'Lab Results'},
    {id: 3, label: 'Medications'},
    {id: 4, label: 'Appointments'},
    {id: 5, label: 'Goals'}
]

const validKeys = new Set(['id']);

function PatientInfoQueryView(props) {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = React.useState(null);

    const path = useLocation();

    const queryParams = qs.parse(path.search, { ignoreQueryPrefix: true });
    const baseUrl = path.pathname;

    React.useEffect(() => {
        for (const tab of tabs) {
            if (tab.label in queryParams) {
                return setCurrentTab(tab);
            }
        }
        setCurrentTab(tabs[0]);
    });

    /*
    // no longer using this
    const history = useHistory();
    const handleTabChange = (event, newIndex) => {
        const queryParams = qs.parse(path.search, { ignoreQueryPrefix: true });
        const baseUrl = path.pathname;
        // delete all keys that are not relevant in query param
        for (const key of Object.keys(queryParams)) {
            if (key !== 'id') {
                delete queryParams[key];
            }
        }
        queryParams[tabs[newIndex].label] = ""; // insert new key
        const newUrl = baseUrl + "?" + qs.stringify(queryParams);
        history.push(newUrl);
    }
    */
    const resolvedPath = resolvePath(baseUrl, queryParams, validKeys);
    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <div>
                    <AvatarWithText role={'Patient'}
                                    imgUrl={samplePatientTableData[0].imgUrl}
                                    text={samplePatientTableData[0].first_name}
                                    size='large' isBolded={true} showRole={true} />
                </div>
                <div>
                    <AntTabs value={currentTab?.id}>
                        {
                            tabs.map(tab => (
                                <AntTab key={tab.id} label={tab.label} to={resolvedPath + "&" + qs.stringify(qs.parse(tab.label))} />
                            ))
                        }
                    </AntTabs>
                </div>
            </div>
            <div className={classes.content}>
                {
                    currentTab?.label === 'Info' && (
                        <Box style={{display: 'flex', flexDirection: 'row', width: '100%'}}> {/* more components to be added! */}
                            <Box style={{width: '30%', paddingRight: '5%'}}>
                                <PatientProfileCard id={queryParams['id']} />
                            </Box>
                            <Box style={{width: '65%', height: '600px'}}>
                                <BloodGlucoseGraphCard id={queryParams['id']} />
                            </Box>
                        </Box>
                    )
                }
                {
                    currentTab?.label === 'Reports' && (
                        <Box style={{display: 'flex', flexDirection: 'row', overflow: 'scroll'}}> {/* more components to be added! */}
                            <Box style={{width: '60%', display: 'flex', flexDirection: 'column'}}>
                                <FoodIntakeGraphCard id={queryParams['id']} />
                                <ActivityGraphCard id={queryParams['id']} containerStyle={{marginTop: '3%'}} />
                            </Box>
                            <Box style={{width: '35%', display: 'flex', flexDirection: 'column', paddingLeft: '5%'}}>
                                <WeightGraphCard id={queryParams['id']} />
                            </Box>
                        </Box>
                    )
                }
                {
                    currentTab?.label === 'Lab Results' && (
                        <div> {/* more components to be added! */}
                            LAB RESULTS - WORK IN PROGRESS
                        </div>
                    )
                }
                {
                    currentTab?.label === 'Medications' && (
                        <div> {/* more components to be added! */}
                            MEDICATIONS - WORK IN PROGRESS
                        </div>
                    )
                }
                {
                    currentTab?.label === 'Appointments' && (
                        <div> {/* more components to be added! */}
                            APPOINTMENTS - WORK IN PROGRESS
                        </div>
                    )
                }
                {
                    currentTab?.label === 'Goals' && (
                        <div> {/* more components to be added! */}
                            GOALS - WORK IN PROGRESS
                        </div>
                    )
                }
            </div>
        </div>
    );
}

function resolvePath(base, queryParams, validKeys) {
    const dupe = {...queryParams};
    for (const key of Object.keys(queryParams)) {
        if (!validKeys.has(key)) {
            delete dupe[key];
        }
    }
    return base + "?" + qs.stringify(dupe);
}

export {PatientInfoQueryView};
