import React from 'react';
import { Line, Chart } from 'react-chartjs-2';
import moment from 'moment';
import {scaleLinear, scaleTime} from 'd3-scale';
import {graphColorScheme, lineGraphStyles} from "./graphConfig";
import _, {debounce} from 'lodash';

class LineGraph extends React.Component {
    constructor(props) {
        super(props);
        this.chart = React.createRef();
    }

    componentDidMount() {
        this.mountOrUpdateHandler();
        window.addEventListener('resize', () => setTimeout(this.mountOrUpdateHandler, 200));
        /*
        // deprecated code but could be useful for other behaviours
        Chart.pluginService.register({
            afterDraw: function(chart, easing) {
                if (chart.tooltip._active && chart.tooltip._active.length) {
                    const activePoint = chart.controller.tooltip._active[0];
                    const ctx = chart.ctx;
                    const x = activePoint.tooltipPosition().x;
                    const y = activePoint.tooltipPosition().y;
                    console.log('TOOL TIP POS: ', x, y);
                    const topY = chart.scales['y-axis-0'].top;
                    const bottomY = chart.scales['y-axis-0'].bottom;

                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, topY);
                    ctx.lineTo(x, bottomY);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = '#aad326';
                    ctx.stroke();
                    ctx.restore();
                }
            }
        });

         */
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.height !== this.props.height || prevProps.width !== this.props.width
            || prevProps.filterKey !== this.props.filterKey) {
            this.mountOrUpdateHandler(); // resize
        }
    }

    mountOrUpdateHandler = () => {
        // set event handler
        const cursorCanvas = document.getElementById(`${this.props.id}-cursor`);
        cursorCanvas.style.width ='100%';
        cursorCanvas.style.height='100%';
        // ...then set the internal size to match
        cursorCanvas.width  = cursorCanvas.offsetWidth;
        cursorCanvas.height = cursorCanvas.offsetHeight;

        const chartInstance = this.chart.current.chartInstance;
        const {top, bottom, left, right} = chartInstance.chartArea;
        //const left = chartInstance.scales['x-axis-0'].getPixelForTick(0);
        //const right = chartInstance.scales['x-axis-0'].getPixelForTick(this.props.data.labels.length - 1);

        const xStartsFrom = chartInstance.chart.scales['x-axis-0'].min;
        const xEnd = chartInstance.chart.scales['x-axis-0'].max;
        const yStartsFrom = chartInstance.chart.scales['y-axis-0'].min;
        const yEnd = chartInstance.chart.scales['y-axis-0'].max;

        this.scales = constructScales(left, right, bottom, top, xStartsFrom, xEnd, yStartsFrom, yEnd);

        const chartElement = document.getElementById(this.props.id);

        chartElement.addEventListener('mousedown', evt => {
            const x = evt.offsetX;
            const y = evt.offsetY;

            const clampedX = Math.max(left, Math.min(x, right));
            const clampedY = Math.max(top, Math.min(y, bottom));
            const xValue = chartInstance.scales['x-axis-0'].getValueForPixel(clampedX).toDate();
            if (this.props.onTooltip) {
                this.props.onTooltip(xValue);
            }
        });

        chartElement.addEventListener('mousemove' , evt => {
            const x = evt.offsetX;
            const y = evt.offsetY;

            //console.log(this.chart.current.chartInstance.scales['x-axis-0'].getValueForPixel(x).format('DD/MM/YYYY HH:mm:ss'));

            // register and propagate events to parent
            /*
            if (this.props.onTooltip && chartInstance.tooltip._active && chartInstance.tooltip._active.length) {
                this.props.onTooltip(chartInstance.tooltip._active.map(activeTooltip => {
                    const index = activeTooltip._index;

                    return {
                        x: this.props.data.labels[index],
                        y: this.props.data.datasets[0].data[index],
                        color: this.props.data.datasets[0].backgroundColor[index],
                        index: index
                    };
                }));
            }
             */

            // manually clamp x-y values.
            const clampedX = Math.max(left, Math.min(x, right));
            const clampedY = Math.max(top, Math.min(y, bottom));

            // clamp tool tip
            if (x > clampedX) {
                return ;
                //openTip(chartInstance, 0, this.props.data.labels.length - 1);
            }

            if (x < left) {
                return ;
                //openTip(chartInstance, 0, 0);
            }

            //console.log(this.scales.scaleX(this.chart.current.chartInstance.scales['x-axis-0'].getValueForPixel(clampedX).toDate()));
            const xValue = chartInstance.scales['x-axis-0'].getValueForPixel(clampedX).toDate();
            const derivedY = getYFromX(xValue, this.props.data.labels, this.props.data.datasets[0].data, this.scales);

            if (this.props.onTooltip && chartInstance.tooltip._active && chartInstance.tooltip._active.length) {
                //this.props.onTooltip(xValue);
            }

            const ctx = cursorCanvas.getContext('2d');
            // clear the canvas. w must be the same as width;
            ctx.clearRect(0, 0, cursorCanvas.width, cursorCanvas.height);

            // draw cursor vertical line
            ctx.beginPath();
            ctx.moveTo(clampedX, top);
            ctx.lineTo(clampedX, bottom);
            ctx.lineWidth = lineGraphStyles.cursorLineWidth;
            ctx.strokeStyle = lineGraphStyles.lineColor;
            ctx.stroke();

            // draw cursor
            ctx.beginPath();
            ctx.arc(clampedX, derivedY, lineGraphStyles.cursorRadius, 0, 2 * Math.PI);
            ctx.strokeStyle = lineGraphStyles.cursorColor;
            ctx.fillStyle = lineGraphStyles.cursorColor;
            ctx.stroke();
            ctx.fill();
        });
    }

    render() {
        const {data, options, width, height, lowerBound, upperBound} = this.props;

        data.datasets.map(d => {
            const backgroundColors = d.data.map(y => {
                if (lowerBound && upperBound) {
                    return (y >= lowerBound && y <= upperBound) ? graphColorScheme.axis_point_color : graphColorScheme.danger_color;
                } else if (lowerBound) {
                    return y >= lowerBound ? graphColorScheme.axis_point_color : graphColorScheme.danger_color;
                } else if (upperBound) {
                    return y <= upperBound ? graphColorScheme.axis_point_color : graphColorScheme.danger_color;
                } else {
                    return graphColorScheme.axis_point_color;
                }
            });
            d.backgroundColor = backgroundColors;
            d.borderColor = graphColorScheme.axis_point_color; // same color
            d.pointBorderColor = backgroundColors;
            d.fill = 'none';
            d.borderWidth = lineGraphStyles.lineWidth; // controls line thickness
            d.pointRadius = lineGraphStyles.pointRadius;
            d.pointHitRadius = lineGraphStyles.pointHitRadius; // detection radius
        });

        // additional options
        options.plugins = {
            datalabels: {
                display: function(context) {
                    return false
                }
            }
        }

        if (lowerBound || upperBound) {
            options.annotation = {
                annotations: [{
                    type: 'box',
                    drawTime: 'beforeDatasetsDraw',
                    yScaleID: 'y-axis-0',
                    yMin: lowerBound,
                    yMax: upperBound,
                    backgroundColor: graphColorScheme.boundary_color
                }]
            }
        }

        return (
            <div style={{width: width || '100%', height: height || '100%', position: 'relative'}} key={this.props.id}>
                <div style={{width: '100%', height: '100%', position: 'absolute'}} key={this.props.id + '-line'}>
                    <Line redraw={true} id={this.props.id} ref={this.chart} data={data} options={options} />
                </div>
                <div style={{width: '100%', height: '100%'}} key={this.props.id + '-cursor-wrapper'}>
                    <canvas id={`${this.props.id}-cursor`} key={this.props.id + '-cursor'}/>
                </div>
            </div>
        )
    }
}

function constructScales(left, right, bottom, top, xStart, xEnd, yStart, yEnd) {
    return {
        scaleX: scaleTime().domain([xStart, xEnd]).range([left, right]),
        scaleY: scaleTime().domain([yStart, yEnd]).range([bottom, top])
    };
}

function getYFromXRecursive(x, labels, data, scales) {
    const l = labels.length;
    if (l <= 2) {
        if (moment(x).isBetween(moment(labels[0]), moment(labels[l - 1]), 'seconds', '[]') && data[0] && data[l - 1]) {
            const xPrev = scales.scaleX(labels[0]);
            const xNext = scales.scaleX(labels[l - 1]);

            const yPrev = scales.scaleY(data[0]);
            const yNext = scales.scaleY(data[l - 1]);
            const xNow = scales.scaleX(x);

            const xIncr = xNow - xPrev;
            const gradient = (yNext - yPrev) / (xNext - xPrev);
            return yPrev + xIncr * gradient;
        } else {
            return ;
        }
    }

    const mid = Math.floor(l / 2);
    if (moment(x).isAfter(moment(labels[mid]), 'seconds')) {
        return getYFromXRecursive(x, labels.slice(mid, l), data.slice(mid, l), scales);
    } else {
        return getYFromXRecursive(x, labels.slice(0, mid + 1), data.slice(0, mid + 1), scales);
    }
}

function getYFromX(x, labels, data, scales) {
    if (labels.length < 1) {
        return ;
    }
    const now = moment(x);
    const earliest = moment(labels[0]);
    const latest = moment(labels[labels.length - 1]);

    if (now.isBetween(earliest, latest, 'seconds', '[]')) {
        return getYFromXRecursive(x, labels, data, scales);
    }
}

function openTip(chartInstance,datasetIndex,pointIndex){
    if(chartInstance.tooltip._active == undefined)
        chartInstance.tooltip._active = []
    let activeElements = chartInstance.tooltip._active;
    let requestedElem = chartInstance.getDatasetMeta(datasetIndex).data[pointIndex];
    for(let i = 0; i < activeElements.length; i++) {
        if(requestedElem._index == activeElements[i]._index)
            return;
    }
    activeElements.push(requestedElem);
    chartInstance.tooltip._active = activeElements;
    chartInstance.tooltip.update(true);
    chartInstance.draw();
}

function closeTip(chartInstance,datasetIndex,pointIndex){
    let activeElements = chartInstance.tooltip._active;
    if(activeElements == undefined || activeElements.length == 0)
        return;
    let requestedElem = chartInstance.getDatasetMeta(datasetIndex).data[pointIndex];
    for(let i = 0; i < activeElements.length; i++) {
        if(requestedElem._index == activeElements[i]._index)  {
            activeElements.splice(i, 1);
            break;
        }
    }
    chartInstance.tooltip._active = activeElements;
    chartInstance.tooltip.update(true);
    chartInstance.draw();
}

export {LineGraph};
