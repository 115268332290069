import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from "@material-ui/core/Box";
import {conditionImages, parsePatient} from "../../../commonFuncs/patientTableFunc";
import {samplePatientTableData} from "./patientTable";
import {capitalize} from "../../../commonFuncs/common";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        borderRadius: '12px'
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
        width: 15, // size of expand icon
        height: 15
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    labelStyle: {
        color: '#4d4d4d',
        fontWeight: 'bold'
    },
    contentStyle: {

    }
}));

// non material ui styles
const styles = {
    rowStyles: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1.5px solid #cdcdcd',
        paddingTop: '10px',
        paddingBottom: '10px'
    }
}

const smallAvatarStyle = {
    width: 20,
    height: 20,
    borderRadius: 0
}

// sample patient type - to be removed once backend has been connected
const samplePatientType = [
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Avatar alt={'priority'} src={conditionImages['priority']} style={smallAvatarStyle} />
        <Typography style={{paddingLeft: '8px', font: 'inherit'}}>
            Priority
        </Typography>
    </div>,
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
        <Avatar alt={'Type-2'} src={conditionImages['type 2']} style={smallAvatarStyle} />
        <Typography style={{paddingLeft: '8px', font: 'inherit'}}>
            Type 2
        </Typography>
    </div>

]

function PatientProfileCard(props) {
    const classes = useStyles();
    const {id} = props;
    const [patient, setPatient] = useState(null);

    React.useEffect(() => {
        const filtered = samplePatientTableData.filter(x => x.patient.trial_id === id);
        if (filtered.length != 0 && patient === null) {
            setPatient(parsePatient(filtered[0]));
        }
    });

    return patient && (
        <Card className={classes.root}>
            <CardContent>
                <CardRow label={'ID'} content={id} />
                <CardRow label={'Gender'} content={patient.gender} />
                <CardRow label={'Date of Birth'} content={'01 Feb 1980'} />
                <CardRow label={'Weight'} content={'59kg'}/>
                <CardExpandableRow label={'Allergies'} contentList={patient.allergies.map(capitalize)} />
                <CardExpandableRow label={'Patient Type'}
                                   contentList={
                                       patient.full_conditions.map(c => <PatientTypeRow imgKey={c} description={capitalize(c)} />)
                                   } />
                <div style={{height: '15px', width: '100%'}} /> {/* additional spacing */}
            </CardContent>
        </Card>
    );

}

function CardRow(props) {
    const classes = useStyles();
    const {label, content, style, labelStyle, contentStyle} = props;
    return (
        <div style={{...styles.rowStyles, ...style}}>
            <Typography className={classes.labelStyle} style={labelStyle}>
                {label}
            </Typography>
            <Typography className={classes.contentStyle} style={contentStyle}>
                {content}
            </Typography>
        </div>
    )
}

function CardExpandableRow(props) {
    const classes = useStyles();
    const {label, contentList, labelStyle} = props;
    const [expanded, setExpanded] = React.useState(false);

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <div style={{...styles.rowStyles, ...{paddingTop: 0, paddingBottom: 0}}}>
                <Typography className={classes.labelStyle} style={labelStyle}>
                    {label}
                </Typography>
                <CardActions disableSpacing>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={() => setExpanded(!expanded)}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
            </div>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {
                    contentList.map(content => (<CardRow key={content} style={{marginLeft: '40px'}} label={content} />))
                }
            </Collapse>
        </div>
    )
}

function PatientTypeRow(props) {
    const {imgKey, description} = props;

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            {   conditionImages[imgKey] ? (
                    <Avatar alt={'Type-2'} src={conditionImages[imgKey]} style={smallAvatarStyle} />
                ) : (<Box style={smallAvatarStyle} />)
            }
            <Typography style={{paddingLeft: '8px', font: 'inherit'}}>
                {description}
            </Typography>
        </div>
    );
}

export {PatientProfileCard};
