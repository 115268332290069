import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, ButtonBase, CardMedia} from '@material-ui/core';
import {Colors} from "../../styles/Colors";


const LeftNavigator = props => {
    const classes = useStyles();
    const {selectedTab, handleTabChange} = props;

    const setActive = (selectedTab, tab) => {
        if(selectedTab === tab){
            return classes.activeTab;
        }
        return classes.inactiveTab;
    }

    const setActiveImgSrc = (selectedTab, tab) => {
        if(selectedTab === tab){
            switch (tab) {
                case 0:
                    return '/images/main/svg/hcw-icon-green-menu-home.svg';
                case 1:
                    return '/images/main/svg/hcw-icon-green-menu-report.svg';
                case 2:
                    return '/images/main/svg/hcw-icon-green-menu-appt.svg';
                case 3:
                    return '/images/main/svg/hcw-icon-green-menu-goals.svg';
            }
        }else{
            switch (tab) {
                case 0:
                    return '/images/main/svg/hcw-icon-white-menu-home.svg';
                case 1:
                    return '/images/main/svg/hcw-icon-white-menu-report.svg';
                case 2:
                    return '/images/main/svg/hcw-icon-white-menu-appt.svg';
                case 3:
                    return '/images/main/svg/hcw-icon-white-menu-goals.svg';
            }
        }
    }

    return (
        <Box className={[classes.container, props.className]}>
            <ButtonBase className={setActive(selectedTab, 0)} onClick={() => {handleTabChange(0)}}>
                <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 0)}/>
            </ButtonBase>
            <ButtonBase className={setActive(selectedTab, 1)} onClick={() => {handleTabChange(1)}}>
                <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 1)}/>
            </ButtonBase>
            <ButtonBase className={setActive(selectedTab, 2)} onClick={() => {handleTabChange(2)}}>
                <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 2)}/>
            </ButtonBase>
            <ButtonBase className={setActive(selectedTab, 3)} onClick={() => {handleTabChange(3)}}>
                <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 3)}/>
            </ButtonBase>
        </Box>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: Colors.themeColor,
        height:'100%',
    },
    tabIcon: {
        marginTop: 15,
        marginBottom: 15,
        width: '60%',
        margin: 'auto',
        objectFit: 'contain',
    },
    activeTab: {
        backgroundColor: Colors.white,
        border: 1,
        borderRadius: 16,
        margin: 5,
    },
    inactiveTab: {
        margin: 5,
    }
})

export default LeftNavigator;
