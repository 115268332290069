import React from 'react';
import { Bar } from 'react-chartjs-2';
import {graphColorScheme} from "./graphConfig";

function BarGraph(props) {
    const {data, options, lowerBound, upperBound, width, height} = props;

    data.datasets.map(d => {
       const backgroundColors = d.data.map(y => {
           if (lowerBound && upperBound) {
               return (y >= lowerBound && y <= upperBound) ? graphColorScheme.axis_point_color : graphColorScheme.danger_color;
           } else if (lowerBound) {
               return y >= lowerBound ? graphColorScheme.axis_point_color : graphColorScheme.danger_color;
           } else if (upperBound) {
               return y <= upperBound ? graphColorScheme.axis_point_color : graphColorScheme.danger_color;
           } else {
               return graphColorScheme.axis_point_color;
           }
       });
       d.backgroundColor = backgroundColors;
       d.borderColor = backgroundColors; // same color
       d.hoverBackgroundColor = backgroundColors.map(color => color == graphColorScheme.axis_point_color ? graphColorScheme.hover_color : color);
    });

    // additional options
    options.plugins = {
        datalabels: {
            display: function(context) {
                return false
            }
        }
    }

    if (lowerBound || upperBound) {
        options.annotation = {
            annotations: [{
                type: 'box',
                drawTime: 'beforeDatasetsDraw',
                yScaleID: 'y-axis-0',
                yMin: lowerBound,
                yMax: upperBound,
                backgroundColor: graphColorScheme.boundary_color
            }]
        }
    }

    return (
        <div style={{width: width || '100%', height: height || '100%'}}>
            <Bar data={data} options={options} />
        </div>
    )
}

export {BarGraph};
