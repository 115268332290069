import React from "react";
import {Box} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";


const CalendarCell = props => {
    const classes = useStyles();

    const {selectedDayNum, cellInfo} = props;

    const cellStyle = () => {
        if(selectedDayNum === cellInfo.day){
            return classes.selectedDay
        }else{
            if(cellInfo.passDay){
                return classes.passDay
            }
            return classes.futureDay
        }
    }

    const textStyle = () => {
        if(selectedDayNum === cellInfo.day){
            return [classes.text, classes.textSelected]
        }
        return classes.text;
    }

    const bottomLineStyle = () => {
        if(selectedDayNum === cellInfo.day){
            return [classes.bottomLine, classes.bottomLineSelected]
        }
        return [classes.bottomLine, classes.bottomLineUnselected];
    }

    return (
        <Box className={[classes.card, classes.cellContainer, cellStyle()]} border={2} >
            {cellInfo !== null &&
                <>
                    <Box className={textStyle()} component='b' color='black'>{cellInfo.day}</Box>
                    {cellInfo.hasAppointment && <Box className={bottomLineStyle()} />}
                </>
            }
        </Box>
    )
};

export default CalendarCell

const useStyles = makeStyles({
    cellContainer: {
        height: 45,
        width: 45,
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    futureDay: {
        backgroundColor: Colors.white,
    },
    passDay: {
        backgroundColor: Colors.grey,
    },
    selectedDay: {
        backgroundColor: Colors.lightGreen,
    },
    card: {
        borderRadius: 10,
        borderColor: Colors.grey,
    },
    bottomLine: {
        width: '50%',
        height: 2,
    },
    bottomLineSelected: {
        backgroundColor: Colors.white,
    },
    bottomLineUnselected: {
        backgroundColor: Colors.lightGreen,
    },
    text: {
        fontSize: 20,
    },
    textSelected:{
        color: Colors.white
    },
})
