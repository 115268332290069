const weeklyWeightGoals = [
    {name: "Lose 0.2 kg per week", value: -0.2},
    {name: "Lose 0.5 kg per week", value: -0.5},
    {name: "Lose 0.8 kg per week", value: -0.8},
    {name: "Lose 1.0 kg per week", value: -1.0},
    {name: "Maintain Weight", value: 0},
    {name: "Gain 0.2 kg per week", value: 0.2},
    {name: "Gain 0.5 kg per week", value: 0.5},
];

export {weeklyWeightGoals};
