import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Link as DomLink} from 'react-router-dom';
import Link from '@material-ui/core/Link';
const useStyles = makeStyles({
    root: {
        color: '#000',
        // set font size here & custom styling
    }
});

// This component wraps around any other component that allows for right click behaviour
function Linkable(props) {
    const {children, to, underline} = props;
    const classes = useStyles();

    return (
        <DomLink to={to || '/'} classes={classes} component={Link} underline={underline || 'none'}>
            {children}
        </DomLink>
    );
}

export {Linkable};
