import React from 'react';
import Typography from "@material-ui/core/Typography";

const innerCircleRatio = 0.5;
const leftMargin = 5;
const rightMargin = 15;
const fontSizeToPixelMultiplier = 4 / 3;

function Legend(props) {
    const {text, color, size} = props;
    const radius = size * fontSizeToPixelMultiplier / 2;

    return (
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{width: radius * 2 + 'px',
                         height: radius * 2 + 'px',
                         borderRadius: radius + "px",
                         backgroundColor: color,
                         display: 'flex',
                         justifyContent: 'center',
                         alignItems: 'center'}}>
                <div style={{ width : innerCircleRatio * radius * 2 + 'px',
                    height: innerCircleRatio * radius * 2 + 'px',
                    borderRadius: innerCircleRatio * radius + 'px',
                    backgroundColor: '#fff'}} />
            </div>
            <Typography style={{marginLeft: leftMargin, marginRight: rightMargin, color: '#7d7d7d', fontSize: size}}>{text}</Typography>
        </div>
    )
}

export {Legend};
