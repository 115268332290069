import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardMedia, ButtonBase, Menu, MenuItem } from '@material-ui/core';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../../redux/reduxMapping";
import {Colors} from "../../styles/Colors";


const TopBarWithBtns = props => {
    const classes = useStyles();
    const {selectedTab, handleTabChange} = props;

    const [navMenuOpen, setNavMenuOpen] = useState(false);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false)

    const openNavMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setNavMenuOpen(true);
    }

    const closeNavMenu = () => {
        setNavMenuOpen(false);
    }

    const NavMenuItemClick = tab => {
        handleTabChange(tab);
        closeNavMenu();
    }

    const openAccountMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setAccountMenuOpen(true);
    }

    const closeAccountMenu = () => {
        setAccountMenuOpen(false);
    }

    const handleViewAccountClick = () => {
        handleTabChange(5);
        closeAccountMenu();
    }

    const handleLogout = () => {
        setAccountMenuOpen(false);
        props.logout();
    }

    const setActive = (selectedTab, tab) => {
        if(selectedTab === tab){
            return classes.activeTab;
        }
        return classes.inactiveTab;
    }

    const setActiveImgSrc = (selectedTab, tab) => {
        if(selectedTab === tab){
            switch (tab) {
                case 0:
                    return '/images/main/svg/hcw-icon-white-menu-home.svg';
                case 1:
                    return '/images/main/svg/hcw-icon-white-menu-report.svg';
                case 2:
                    return '/images/main/svg/hcw-icon-white-menu-appt.svg';
                case 3:
                    return '/images/main/svg/hcw-icon-white-menu-goals.svg';
            }
        }else{
            switch (tab) {
                case 0:
                    return '/images/main/svg/hcw-icon-green-menu-home.svg';
                case 1:
                    return '/images/main/svg/hcw-icon-green-menu-report.svg';
                case 2:
                    return '/images/main/svg/hcw-icon-green-menu-appt.svg';
                case 3:
                    return '/images/main/svg/hcw-icon-green-menu-goals.svg';
            }
        }
    }

    return (
        <>
            <Box className={[classes.container, props.className]}>
                <Box className={classes.menuContainer}>
                    <ButtonBase onClick={openNavMenu}>
                        <CardMedia className={classes.menu} component="img" src="/images/main/svg/hcw-icon-white-menu-home.svg"/>
                    </ButtonBase>
                </Box>
                <Box className={classes.topBarContainer}>
                    <CardMedia className={classes.logo} component="img" src="/images/login/empower_icon.png"/>

                    <Box className={classes.rightContainer}>
                        <ButtonBase onClick={() => {handleTabChange(4)}}>
                            <CardMedia className={classes.rightIcon} component="img" src="/images/main/svg/hcw-icon-navy-menu-chat.svg"/>
                        </ButtonBase>
                        <ButtonBase>
                            <CardMedia className={classes.rightIcon} component="img" src="/images/main/svg/hcw-icon-navy-menu-alert.svg"/>
                        </ButtonBase>
                        <ButtonBase onClick={openAccountMenu}>
                            <CardMedia className={classes.rightIcon} component="img" src="/images/main/svg/hcw-icon-navy-menu-account.svg"/>
                        </ButtonBase>
                    </Box>
                </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={navMenuOpen}
                onClose={closeNavMenu}>
                <MenuItem className={setActive(selectedTab, 0)} onClick={() => {NavMenuItemClick(0)}}>
                    <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 0)}/>
                </MenuItem>
                <MenuItem className={setActive(selectedTab, 1)} onClick={() => {NavMenuItemClick(1)}}>
                    <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 1)}/>
                </MenuItem>
                <MenuItem className={setActive(selectedTab, 2)} onClick={() => {NavMenuItemClick(2)}}>
                    <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 2)}/>
                </MenuItem>
                <MenuItem className={setActive(selectedTab, 3)} onClick={() => {NavMenuItemClick(3)}}>
                    <CardMedia className={classes.tabIcon} component="img" src={setActiveImgSrc(selectedTab, 3)}/>
                </MenuItem>
            </Menu>
            <Menu
                anchorEl={anchorEl}
                open={accountMenuOpen}
                onClose={closeAccountMenu}>
                <MenuItem onClick={handleViewAccountClick}>View account</MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
        </>
    )
};

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        boxShadow: '0 3px 5px 2px rgba(105, 105, 105, .3)',
        display: 'flex',
        flex: 1,
    },
    menuContainer:{
        height:'100%',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        backgroundColor: Colors.themeColor,
        [theme.breakpoints.up('xs')]: {
            width: '5%',
            minWidth: 70,
        },
        [theme.breakpoints.up('sm')]: {
            width: '5%',
            minWidth: 70,
        },
        [theme.breakpoints.up('md')]: {
            width: '5%',
            minWidth: 70,
        },
        [theme.breakpoints.up('lg')]: {
            width: 0,
            minWidth: 0,
        },
        [theme.breakpoints.up('xl')]: {
            width: 0,
            minWidth: 0,
        },
    },
    topBarContainer:{
        height:'100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItem: 'center',
        [theme.breakpoints.up('xs')]: {
            width: '95%',
            minWidth: 'calc(100% - 70)',
        },
        [theme.breakpoints.up('sm')]: {
            width: '95%',
            minWidth: 'calc(100% - 70)',
        },
        [theme.breakpoints.up('md')]: {
            width: '95%',
            minWidth: 'calc(100% - 70)',
        },
        [theme.breakpoints.up('lg')]: {
            width: '100%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '100%',
        },
    },
    rightContainer: {
        height:'100%',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
    },
    menu:{
        width: '90%',
        height: '90%',
        aspectRatio:1,
        objectFit: 'contain',
    },
    logo: {
        width: 100,
        height: '100%',
        aspectRatio:1,
        objectFit: 'contain',
    },
    rightIcon: {
        margin: 'auto',
        height: '60%',
        width: 70,
        objectFit: 'contain',
    },
    inactiveTab: {
        backgroundColor: Colors.white,
        border: 1,
        borderRadius: 16,
        margin:5,
    },
    activeTab: {
        backgroundColor: Colors.themeColor,
        border: 1,
        borderRadius: 16,
        margin:5,
    },
    tabIcon: {
        width: 50,
        height: 50,
        objectFit: 'contain',
    },
}));

export default connect(mapStateToProps, mapDispatchToProps)(TopBarWithBtns)
