//login url
const login = '/auth/physician-login';
const security_questions = '/auth/physician-security-questions/';
const all_security_questions = '/auth/security-questions/all';
const set_security_questions = `/auth/security-answers/physician`;

//appointment url
const appointments = '/profile/physician/appointment';
const all_patient_profiles = '/profile/physician/patient-profiles';

// account info
export const physician_profile = "/profile/physician";
export const physician_reset_password = "auth/physician/password-reset/reset";

const goal = `/goal/physician`; // GET, POST, DELETE

export {login, security_questions, all_security_questions, set_security_questions, appointments, all_patient_profiles, goal};

