import React from 'react';
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Box from '@material-ui/core/Box';
import {getLineGraphOptions, graphColorScheme, getBarGraphOptions} from "./graphConfig";
import {Legend} from "./legend";
import {BarGraph} from "./barChart";
import {getSampleData, samplePieData, sampleFoodTableData} from "./sampleData";
import {makeStyles} from "@material-ui/styles";
import {LineGraph} from "./lineChart";
import {ButtonBase, CardMedia, Modal} from "@material-ui/core";
import {PieChart} from "./pieChart";
import {Colors} from "../../styles/Colors";
import moment from 'moment';
import CollapsibleTable from "./foodCollapsibleTable";
import {roundNearestDate} from "../../commonFuncs/graphFuncs";
import {DAY_FILTER_KEY, GraphTimeFilterTabs, WEEK_FILTER_KEY} from "./graphTimeFilterTabs";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
    legendContainer: {
        display: "flex",
        flexDirection: 'row',
    },
    card: {
        padding: '2%',
        borderRadius: 10,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#000'
    },
    graphTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        color: '#adadad'
    },
    media: {
        width: 20,
        height: 20
    },
    modalContent: {
        width: '50%',
        height: '450px',
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
        outline: "none",
        borderRadius: 16,
        padding: '2%'
    },
    closeButton: {
        backgroundColor: Colors.grey,
        marginLeft: '24%',
        marginRight: '24%',
        borderRadius: 10,
        height: '16%'
    }
}));

function FoodIntakeGraphCard(props) {
    const classes = useStyles();
    const {containerStyle} = props;

    const [openPie, setOpenPie] = React.useState(false);
    const [timeFilter, setTimeFilter] = React.useState(WEEK_FILTER_KEY);
    const [calorieData, setCalorieData] = React.useState(getSampleData(WEEK_FILTER_KEY));

    // only used for DAY_FILTER KEY
    const [datePickerDate, setDatePickerDate] = React.useState(getSampleData(WEEK_FILTER_KEY).labels[5]);

    const onTimeFilterChange = (newTimeFilter) => {
        if (newTimeFilter !== timeFilter) {
            // todo make async call to update data again.
            setTimeFilter(newTimeFilter);
            setCalorieData(getSampleData(newTimeFilter));
        }
    }

    const onDatePickerChange = (newDate) => {
        setDatePickerDate(newDate);
        // todo make async call to update data again.
    }

    return (
        <React.Fragment>
            <Card className={classes.card} style={containerStyle}>
                <Box className={classes.header}>
                    <Typography className={classes.title}>Food Intake Overview</Typography>
                    <Box className={classes.header}>
                        {
                            timeFilter === DAY_FILTER_KEY && (
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        variant="inline"
                                        format="MM/dd/yyyy"
                                        margin="small"
                                        style={{width: 150}}
                                        value={datePickerDate}
                                        onChange={onDatePickerChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            )
                        }
                        <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', paddingLeft: 10}}>
                            <ButtonBase onClick={()=>setOpenPie(true)}>
                                <CardMedia className={classes.media} component="img" src="/images/main/svg/hcw-icon-green-toggle-panel.svg"/>
                            </ButtonBase>
                            <GraphTimeFilterTabs style={{marginLeft: 20}} currentFilter={timeFilter} setCurrentFilter={onTimeFilterChange} />
                        </Box>
                    </Box>
                </Box>
                <Box>
                    <Typography className={classes.graphTitle}>Calories Consumed - kcal</Typography>
                    <Box className={classes.legendContainer}>
                        <Legend color={'#aad326'} text={'Healthy'} size={14} />
                        <Legend color={'red'} text={'Unhealthy'} size={14}/>
                    </Box>
                    <BarGraph data={calorieData} options={getBarGraphOptions(timeFilter)} />
                </Box>
            </Card>
            <Modal open={openPie} onClose={()=>setOpenPie(false)} style={{justifyContent: 'center', alignItems: 'center', display: 'flex'}}>
                <Box className={classes.modalContent}>
                    <Typography className={classes.title}>Nutrition Distribution</Typography>
                    <PieChart data={samplePieData} />
                    <ButtonBase className={classes.closeButton} onClick={()=>setOpenPie(false)}>
                        <Typography style={{fontSize: 16, fontWeight: 'bold', color: '#000'}}>Close</Typography>
                    </ButtonBase>
                </Box>
            </Modal>
        </React.Fragment>
    )
}

function ActivityGraphCard(props) {
    const classes = useStyles();
    const {containerStyle} = props;
    const [timeFilter, setTimeFilter] = React.useState(WEEK_FILTER_KEY);
    const [stepsTakenData, setStepsTakenData] = React.useState(getSampleData(WEEK_FILTER_KEY));
    const [caloriesBurntData, setCaloriesBurntData] = React.useState(getSampleData(WEEK_FILTER_KEY));
    const [durationData, setDurationData] = React.useState(getSampleData(WEEK_FILTER_KEY));

    // only used for DAY_FILTER KEY
    const [datePickerDate, setDatePickerDate] = React.useState(getSampleData(WEEK_FILTER_KEY).labels[5]);

    const onTimeFilterChange = (newTimeFilter) => {
        if (newTimeFilter !== timeFilter) {
            // todo make async call to update data again.
            setTimeFilter(newTimeFilter);
            setCaloriesBurntData(getSampleData(newTimeFilter));
            setStepsTakenData(getSampleData(newTimeFilter));
            setDurationData(getSampleData(newTimeFilter));
        }
    }

    const onDatePickerChange = (newDate) => {
        setDatePickerDate(newDate);
        // todo make async call to update data again.
    }

    return (
        <Card className={classes.card} style={containerStyle}>
            <Box className={classes.header}>
                <Typography className={classes.title}>Activity Overview</Typography>
                <Box className={classes.header}>
                    {
                        timeFilter === DAY_FILTER_KEY && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="small"
                                    style={{width: 150}}
                                    value={datePickerDate}
                                    onChange={onDatePickerChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        )
                    }
                    <Box style={{paddingLeft: 5}}>
                        <GraphTimeFilterTabs currentFilter={timeFilter}
                                             setCurrentFilter={onTimeFilterChange} />
                    </Box>
                </Box>
            </Box>
            <Box>
                <Typography className={classes.graphTitle}>Steps Taken</Typography>
                <BarGraph data={stepsTakenData} options={getBarGraphOptions(timeFilter, 0, 5000)} />
            </Box>
            <Box>
                <Typography className={classes.graphTitle}>Calories Burnt - kcal</Typography>
                <BarGraph data={caloriesBurntData} options={getBarGraphOptions(timeFilter, 0, 200)} />
            </Box>
            <Box>
                <Typography className={classes.graphTitle}>Duration - min</Typography>
                <BarGraph data={durationData} options={getBarGraphOptions(timeFilter, 0, 100)} />
            </Box>
        </Card>
    )
}

function WeightGraphCard(props) {
    const classes = useStyles();
    const {containerStyle} = props;
    const [timeFilter, setTimeFilter] = React.useState(WEEK_FILTER_KEY);
    const [weightData, setWeightData] = React.useState(getSampleData(WEEK_FILTER_KEY));

    // only used for DAY_FILTER KEY
    const [datePickerDate, setDatePickerDate] = React.useState(getSampleData(WEEK_FILTER_KEY).labels[5]);

    const onTimeFilterChange = (newTimeFilter) => {
        if (newTimeFilter !== timeFilter) {
            // todo make async call to update data again.
            setTimeFilter(newTimeFilter);
            setWeightData(getSampleData(newTimeFilter));
        }
    }

    const onDatePickerChange = (newDate) => {
        setDatePickerDate(newDate);
        // todo make async call to update data again.
    }

    return (
        <Card className={classes.card} style={containerStyle}>
            <Box className={classes.header}>
                <Typography className={classes.title}>Weight Overview</Typography>
                <Box>
                    <GraphTimeFilterTabs currentFilter={timeFilter} setCurrentFilter={onTimeFilterChange} />
                </Box>
            </Box>
            <Box>
                <Typography className={classes.graphTitle}>Progress - kg</Typography>
                <Box className={classes.legendContainer}>
                    <Legend color={graphColorScheme.boundary_color} text={'Target Range (2.0K - 2.2K)'} size={14}/>
                </Box>
                <LineGraph id={'weight-graph'}
                           data={weightData}
                           lowerBound={2000}
                           upperBound={2200}
                           options={getLineGraphOptions(timeFilter, 30, 90)}
                           filterKey={timeFilter}
                           height={'240px'} />
            </Box>
        </Card>
    )
}

function BloodGlucoseGraphCard(props) {
    const classes = useStyles();
    const {containerStyle} = props;
    const [timeFilter, setTimeFilter] = React.useState(WEEK_FILTER_KEY);
    const [toolTipDate, setToolTipDate] = React.useState(getSampleData(WEEK_FILTER_KEY).labels[5]);
    const [bloodGlucoseData, setBloodGlucoseData] = React.useState(getSampleData(WEEK_FILTER_KEY));

    // only used for DAY_FILTER KEY
    const [datePickerDate, setDatePickerDate] = React.useState(getSampleData(WEEK_FILTER_KEY).labels[5]);

    const onTooltip = (newDate) => {
        setToolTipDate(roundNearestDate(newDate, timeFilter));
        // todo: update data in state by making async call
    };

    const onTimeFilterChange = (newTimeFilter) => {
        if (newTimeFilter !== timeFilter) {
            // todo make async call to update data again.
            setTimeFilter(newTimeFilter);
            setBloodGlucoseData(getSampleData(newTimeFilter));
        }
    }

    const onDatePickerChange = (newDate) => {
        setDatePickerDate(newDate);
        // todo make async call to update data again.
        setToolTipDate(newDate);
    }

    return (
        <Card className={classes.card} style={containerStyle}>
            <Box className={classes.header}>
                <Typography className={classes.title}>Blood Glucose</Typography>
                <Box className={classes.header}>
                    {
                        timeFilter === DAY_FILTER_KEY && (
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    margin="small"
                                    value={datePickerDate}
                                    style={{width: 150}}
                                    onChange={onDatePickerChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        )
                    }
                    <Box style={{paddingLeft: 5}}>
                        <GraphTimeFilterTabs currentFilter={timeFilter}
                                             setCurrentFilter={onTimeFilterChange} />
                    </Box>
                </Box>
            </Box>
            <Box style={{width: '100%', height: '60%'}}>
                <Typography className={classes.graphTitle}>Readings - mmol/L</Typography>
                <Box className={classes.legendContainer}>
                    <Legend color={graphColorScheme.boundary_color}
                            text={'Target Range (2.0K - 2.2K)'}
                            size={14}/>
                </Box>
                <LineGraph id={'blood-glucose-graph'}
                           data={bloodGlucoseData}
                           lowerBound={2000}
                           upperBound={2200}
                           options={getLineGraphOptions(timeFilter, 1300, 2500)}
                           height={'300px'}
                           filterKey={timeFilter}
                           onTooltip={onTooltip}/>
                {
                    toolTipDate && <Typography>{moment(toolTipDate).format('DD MMM YYYY')}</Typography>
                }
                <CollapsibleTable rows={sampleFoodTableData} />
            </Box>
        </Card>
    )
}

export {FoodIntakeGraphCard, ActivityGraphCard, WeightGraphCard, BloodGlucoseGraphCard};
