import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

// add your own config if you need custom size.
const sizeConfig = {
    small: {
        img: {
            height: 30,
            width: 30,
        },
        roleText: {
            fontSize: 12,
            color: '#8d8d8d',
        },
        text: {
            fontSize: 16,
            color: '#000',
        }
    },
    medium: {
        img: {
            height: 60,
            width: 60,
        },
        roleText: {
            fontSize: 16,
            color: '#8d8d8d',
            marginTop: 10
        },
        text: {
            fontSize: 28,
            color: '#000',
            marginTop: -10
        }
    },
    large: {
        img: {
            height: 80,
            width: 80,
        },
        roleText: {
            fontSize: 20,
            color: '#8d8d8d',
            marginTop: 10
        },
        text: {
            fontSize: 32,
            color: '#000',
            marginTop: -10,
        }
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 12
    }
}));

const defaultSize = 'small'; //can be small medium or large, depending on what keys are present in sizeConfig

function AvatarWithText(props) {
    const {imgUrl, role, text, showRole, alt, size, isBolded} = props;
    const classes = useStyles();

    const s = size ? size : defaultSize;

    return (
        <div className={classes.root}>
            <Avatar alt={alt} src={imgUrl} style={sizeConfig[s].img} />
            <div className={classes.textContainer}>
                {
                    showRole ? (
                    <React.Fragment>
                        <Typography style={{...sizeConfig[s].roleText, ...{fontWeight:  isBolded ? 'bold' : 'normal' }}}>
                            {role}
                        </Typography>
                        <Typography style={{...sizeConfig[s].text, ...{fontWeight:  isBolded ? 'bold' : 'normal' }}}>
                            {text}
                        </Typography>
                    </React.Fragment>
                    ) : (
                        <Typography style={{...sizeConfig[s].text, ...{fontWeight:  isBolded ? 'bold' : 'normal' }}}>
                            {text}
                        </Typography>
                    )
                }
            </div>
        </div>
    );
}

export {AvatarWithText};
