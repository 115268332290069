import {login, security_questions, all_security_questions, set_security_questions} from "./urls";
import {baseRequestGet, baseRequestPost} from "./requestBase";


const requestLogin = (username, password, remember) => {
    const authData = {
        username: username,
        password: password,
        remember: remember
    };

    return baseRequestPost(login, 'requestLogin', authData);
}

const requestSecurityQuestions = (username) => {
    let url = security_questions + username;
    return baseRequestGet(url, 'requestSecurityQuestions');
}

const requestAllSecurityQuestions = () => {
    return baseRequestGet(all_security_questions, 'requestAllSecurityQuestions');
}

const requestSetSecurityQuestions = (questions, answers) => {
    let data = questions.map((question, index) => {
        return {
            question_id: question._id,
            answer: answers[index]
        }
    })
    return baseRequestPost(set_security_questions, 'requestSetSecurityQuestions', data);
}

export {requestLogin, requestSecurityQuestions, requestAllSecurityQuestions, requestSetSecurityQuestions}
