import axios from 'axios'
import {store} from '../redux/helpers/store'
import {logout} from "../redux/actions";

const instance = axios.create({});

instance.interceptors.response.use((response) => response, (error) => {
    if(error.response.status === 401)
        store.dispatch(logout());
    else
        throw error;
});

export default instance;
