import {Colors} from "./Colors";

export const globalStyles = {
    container: {
        backgroundColor: Colors.grey,
        flex: 1,
        padding: 20,
        overflow: 'scroll'
    },
    pageTitle:{
        fontSize: 30,
        marginTop: 10,
        marginBottom: 20,
        fontWeight: 'bold'
    },
    pageTitleMedium: {
        fontSize: 26,
        marginTop: 5,
        marginBottom: 5,
        fontWeight: 'bold'
    },
    pageTitleSmall: {
        fontSize: 16,
        marginTop: 5,
        marginBottom: 5,
        fontWeight: 'bold'
    },
    pageSubTitleMedium: {
        fontSize: 26,
        fontWeight: 'bold',
        marginBottom: 5,
        color: Colors.darkGrey
    },
    pageSubTitleSmall: {
        fontSize: 16,
        fontWeight: 'bold',
        color: Colors.darkGrey
    }
}
