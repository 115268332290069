import {goal} from "./urls";
import axiosInstance from "../util/axios";
import {baseRequestDelete, baseRequestGet, baseRequestPost} from "./requestBase";

/*
const baseRequestGet = async (url) => {
    return new Promise((resolve, reject) => {
        axiosInstance.get(url).then(response=> {
            resolve(response);
        }).catch(error =>{
            console.log('Request Error - ' + ' : ' + error.message);
            reject(error);
        })
    })
}

const baseRequestPost = (url, dataObj) => {
    return new Promise((resolve, reject) => {
        axiosInstance.post(url, dataObj)
            .then(response=> {
                resolve(response);
            })
            .catch(error =>{
                console.log('Request Error - ' + ' : ' + error.message);
                reject(error);
            })
    })
}

const baseRequestDelete = (url) => {
    return new Promise((resolve, reject) => {
        axiosInstance.delete(url)
            .then(response=> {
                resolve(response);
            })
            .catch(error =>{
                console.log('Request Error - ' + ' : ' + error.message);
                reject(error);
            })
    })
}
 */

function requestAllGoalsFromPhysician() {
    return baseRequestGet(goal, 'get all goals', null);
}

function requestUpsertNewPreconfiguredGoal(goalObj, goalType) {
    return baseRequestPost(goal + `/${goalType}`, 'upsert a goal' , goalObj);
}

function requestDeletePreconfiguredGoal(goalType, goalId) {
    return baseRequestDelete(goal + `/${goalType}?goal_id=${goalId}`, 'delete one goal', null);
}

export {requestAllGoalsFromPhysician, requestUpsertNewPreconfiguredGoal, requestDeletePreconfiguredGoal};
