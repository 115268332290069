import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {PreconfiguredGoalTable} from "../components/goal/PreconfiguredGoalTable";
import {globalStyles} from "../styles/globalStyles";

const SetGoal = () => {
    document.title = "Empower - Goals";

    return (
        <Box style={globalStyles.container} >
            <Typography component={'b'} style={globalStyles.pageTitle}>Set Goal</Typography>
            <Box style={{height: 15}} />
            <PreconfiguredGoalTable />
        </Box>
    )
}

export default SetGoal;
