import React from "react";
import {Box, CardMedia, Grid, ButtonBase} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";
import UserCell from "./userCell";
import Moment from "moment";


const AppointmentCell = props => {
    const classes = useStyles();

    const {rescheduleFunc, deleteFunc, appointment} = props;
    const timePeriod = () => {
        return Moment(appointment.start, 'DD-MM-YYYY HH:mm:ss').format('HH:mm') + ' - ' + Moment(appointment.end, 'DD-MM-YYYY HH:mm:ss').format('HH:mm')
    }

    const borderStyle = () => {
        if(appointment.clash){
            return classes.clashBorder
        }
        return classes.normalBorder
    }

    return (
        <Box className={[classes.container, borderStyle(), props.className]} border={1} boxShadow={2}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <UserCell usertype={'Patient'} username={appointment.patient.first_name} />
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.horizontalContainer}>
                        {appointment.title}
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.horizontalContainer}>
                        {timePeriod()}
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.horizontalContainer} >
                        <CardMedia className={classes.actionIcon} component="img" src="/images/main/svg/hcw-icon-lightgreen-chat.svg"/>
                        <ButtonBase onClick={() => {rescheduleFunc()}}>
                            <CardMedia className={classes.actionIcon} component="img" src="/images/main/svg/hcw-icon-red-reschedule.svg"/>
                        </ButtonBase>
                        <ButtonBase onClick={() => {deleteFunc()}}>
                            <CardMedia className={classes.actionIcon} component="img" src="/images/main/svg/hcw-icon-red-remove.svg"/>
                        </ButtonBase>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
};

export default AppointmentCell;

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width:'100%',
        borderRadius: 10,
        backgroundColor: Colors.white,
        padding:10,
        justifyContent: 'center',
        alignItems:'center',
    },
    normalBorder:{
        borderColor: Colors.grey,
    },
    clashBorder:{
        borderColor: Colors.red,
        borderWidth: 2,
    },
    horizontalContainer:{
        display: 'flex',
        alignItems:'center',
        height:'100%',
    },
    verticalContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        height:'100%',
    },
    actionIcon: {
        height:30,
        width:30,
        marginLeft: 10,
        marginRight: 10,
        aspectRatio:1,
        objectFit: 'contain',
    }
})
