import React, {useEffect, useState} from 'react';
import MaterialTable, {MTableBodyRow, MTableToolbar, MTableHeader} from 'material-table';
import {AvatarWithText} from "../main/avatarWithText";
import {tableIcons} from "../main/patientInfo/patientTable";
import {Paper, Avatar, Box, Typography} from "@material-ui/core";
import ButtonBase from "@material-ui/core/ButtonBase";
import {Colors} from "../../styles/Colors";
import {AddGoalModal} from "./AddGoalModal";
import {EditGoalModal} from "./EditGoalModal";
import {requestAllGoalsFromPhysician, requestDeletePreconfiguredGoal} from "../../netCalls/requestsGoalModule";

const options = {
    sorting: true,
    emptyRowsWhenPaging: false,
    pageSize: 2^31-1,
    headerStyle: {
        backgroundColor: 'transparent',
        borderTop: '1px solid #dfdfdf'
    },
    actionsColumnIndex: -1
}

const tableColumns = [
    {
        title: 'Name',
        field: 'name',
        type: 'string'
    },
    {
        title: 'Goal Type',
        type: 'string',
        field: 'goal_type',
        render: rowData => {
            const formatted = rowData.goal_type.replaceAll('-', ' ').replaceAll('_', ' ');
            return (<Typography>{formatted[0].toUpperCase() + formatted.slice(1)}</Typography>);
        }
    }
];

const actionButtonStyle = {
    width: 25,
    height: 25,
    borderRadius: 0
}

function flattenGoals(goalsObj) {
    let result = [];
    for (const key of Object.keys(goalsObj)) {
        const goalList = goalsObj[key];
        goalList.map(goal => {
            goal.goal_type = key;
        });
        result = result.concat(goalList);
    }

    return result;
}

function PreconfiguredGoalTable(props) {
    const [selectedRow, setSelectedRow] = React.useState(null);
    const [addGoalModalOpen, setAddGoalModalOpen] = useState(false);
    const [allGoalData, setAllGoalData] = useState([]);

    useEffect(async () => {
        // load data here
        let resp = await requestAllGoalsFromPhysician();

        const flattenedGoals = flattenGoals(resp.data);

        if (flattenedGoals.length !== allGoalData.length) {
            setAllGoalData(flattenedGoals);
        } else {
            for (let i = 0; i < flattenedGoals.length; i++) {
                for (const [key, value] of Object.entries(flattenedGoals[i])) {
                    if (value !== allGoalData[i][key]) {
                        setAllGoalData(flattenedGoals);
                        return;
                    }
                }
            }
        }
    })

    const actions = [
        {
            icon: () => <Avatar src={'images/main/svg/hcw-icon-lightgreen-info.svg'} style={actionButtonStyle} />,
            tooltip: 'Info',
            onClick: (event, rowData) => {
                setSelectedRow(rowData);
            }
        },
        {
            icon: () => <Avatar src={'images/main/svg/hcw-icon-red-remove.svg'} style={actionButtonStyle} />,
            tooltip: 'Delete Goal',
            onClick: async (event, rowData) => {
                let resp = await requestDeletePreconfiguredGoal(rowData.goal_type, rowData._id);
                setAllGoalData(allGoalData.filter(g => g.goal_type !== rowData.goal_type && g._id !== rowData._id));
            }
        }
    ];

    return (
        <React.Fragment>
            <MaterialTable components={{
                Toolbar: (props) => (
                    <Box style={{marginLeft: 12, marginRight: 12, paddingTop: 12, paddingBottom: 12}}>
                        <Typography style={{fontWeight: 'bold'}}>Preconfigured Goals</Typography>
                    </Box>
                ),
                Container: props => <Paper {...props} />,
                Pagination: props => (
                    <>
                        <ButtonBase onClick={() => setAddGoalModalOpen(true)}>
                            <Box style={{display: 'flex', flexDirection: 'row', alignItems: 'center', margin: 12}}>
                                <Avatar src={'images/main/svg/hcw-icon-lightgreen-add.svg'} style={{width: 22, height: 22, borderRadius:0}} />
                                <Typography style={{marginLeft: 5, color: Colors.lightGreen}}>
                                    Add Goal
                                </Typography>
                            </Box>
                        </ButtonBase>
                        <AddGoalModal visible={addGoalModalOpen} handleClose={()=>setAddGoalModalOpen(false)} />
                        <EditGoalModal visible={selectedRow !== null}
                                       handleClose={() => setSelectedRow(null)}
                                       initialData={selectedRow}
                        />
                    </>
                )
            }}
                           columns={tableColumns}
                           data={allGoalData}
                           options={options}
                           actions={actions}
                           icons={tableIcons} />
        </React.Fragment>
    )
}

export {PreconfiguredGoalTable};
