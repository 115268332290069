import {physician_profile, physician_reset_password} from "./urls";
import {baseRequestGet, baseRequestPost} from "./requestBase";


export const requestUpdateProfile = ({name}) => {
    const updates = {};
    if (name) {
        updates.first_name = name;
    }
    return baseRequestPost(physician_profile, 'requestUpdateProfile', updates);
};

export const requestGetProfile = () => {
    return baseRequestGet(physician_profile, 'requestGetProfile');
};

export const requestUpdatePassword = (updates) => {
    return baseRequestPost(physician_reset_password, 'requestUpdatePassword', updates);
};

export const requestUpdateSecurityQuestions = (updates) => {

    return baseRequestPost(physician_profile, 'requestUpdateSecurityQuestions', updates);
};
