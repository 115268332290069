import React from 'react';
import MaterialTable, { MTableBodyRow } from 'material-table';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Avatar from "@material-ui/core/Avatar";
import Tooltip from '@material-ui/core/Tooltip';
import { DropDownSelector } from '../dropDownSelector';
import {Searchbar} from "../searchbar";
import {AvatarWithText} from "../avatarWithText";
import { useLocation, useHistory } from 'react-router-dom';
import {conditionImages, parsePatient} from "../../../commonFuncs/patientTableFunc";
import {samplePatientTableData} from "../../graphs/sampleData";
const qs = require('qs');

const filterSelectChoices = [
    {name: "All Patients", value: 'all'},
    {name: "Recent Patients", value: 'recent'},
    {name: "Steroid-Induced / Steroid with Chemo", value: "steroid"},
    {name: "Newly Started Insulin", value: "started-insulin"},
    {name: "Hyperglycemia Risk", value: 'hyperglycemia'},
    {name: "Hypoglycemia Risk", value: 'hypoglycemia'},
    {name: "Pregnant", value: 'pregnant'},
    {name: "Priority", value: 'priority'},
    {name: "Inactive", value: 'inactive'}
]

const {forwardRef} = React;

const tableColumns = [
    {
        title: 'Name',
        field: 'first_name',
        render: rowData => (
            <AvatarWithText role={'Patient'} imgUrl={rowData.imgUrl} showRole={false} text={rowData.first_name} size='small' isBolded={false} />
        )

    },
    {
        title: 'Diabetes Type',
        type: 'numeric',
        field: 'diabetes_type'
    },
    {
        title: 'Conditions',
        field: 'conditions',
        render: rowData => (
            <PatientConditions conditions={rowData.conditions} />
        )
    }
]

const options = {
    sorting: true,
    paging:true,
        pageSize:10,       // make initial page size
        emptyRowsWhenPaging: true,   //to make page size fix in case of less data rows
        pageSizeOptions:[],    // rows selection options
}

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

function PatientTable(props) {
    const [currentChoiceValue, setCurrentChoiceValue] = React.useState(filterSelectChoices[0].value);
    const [value, setValue] = React.useState('');
    const [patients, setPatients] = React.useState(samplePatientTableData.map(parsePatient));
    const baseUrl = '/patientInfo'; // fixed. so that any reference of click will redirect to this url
    const history = useHistory();

    React.useEffect(() => {

    });

    const handleRowClick = (event, rowData) => {
        // do routing here
        if (rowData.id) {
            history.push(baseUrl + "?id=" + rowData.id);
        }
    }

    const onContextMenu = (index) => (event) => {
        // open custom context menu here
        event.preventDefault();
        return false;
    }

    const filteredData = patients
        .filter(d => d.first_name.toLowerCase().indexOf(value.toLowerCase()) === 0
            && (currentChoiceValue === 'all' ? true : d.conditions.includes(currentChoiceValue)));

    return (
        <MaterialTable key={'table'} components={{
            Toolbar: (props) => <PatientTableToolbar
                                                            currentChoiceValue={currentChoiceValue}
                                                            onChoiceChange={setCurrentChoiceValue}
                                                            value={value}
                                                            setValue={setValue}
                                                            />,
            Row: (props) => <MTableBodyRow {...props} onContextMenu={onContextMenu(props.index)} />,
        }}
                        /*
                        title={<PatientFilterSelect
                                    onFilterChange={setCurrentChoiceValue}
                                    currentChoiceValue={currentChoiceValue}/>}
                                    */
                       columns={tableColumns}
                       data={filteredData}
                       options={options}
                       icons={tableIcons}
                       onRowClick={handleRowClick}
        />
    )
}

// Polymorphism from other components
function PatientFilterSelect(props) {
    const {onChoiceChange, currentChoiceValue} = props;
    return (
        <DropDownSelector choices={filterSelectChoices}
                          style={{padding: 0}}
                          onChoiceChange={onChoiceChange}
                          currentChoiceValue={currentChoiceValue}  />
        );
}

// tool bar should have dropdown filter select on the left and search bar on the right
function PatientTableToolbar(props) {
    const {currentChoiceValue, onChoiceChange, value, setValue} = props;
    return (
        <div key='patient-table-tool-bar'
             style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 15}}>
            <PatientFilterSelect currentChoiceValue={currentChoiceValue} onChoiceChange={onChoiceChange} />
            <Searchbar value={value} onValueChange={setValue} />
        </div>
    )
}

function PatientConditions(props) {
    const {conditions} = props;

    return (
        <div style={{flexDirection: 'row', display: 'flex'}}>
            {
                conditions.map(cond => (
                    <Tooltip title={cond[0].toUpperCase() + cond.slice(1)}>
                        <Avatar style={{width: 30, height: 30, paddingRight: 5, borderRadius: 0}} src={conditionImages[cond]}/>
                    </Tooltip>
                ))
            }
        </div>
    )
}

export {PatientTable, PatientTableToolbar, samplePatientTableData, PatientFilterSelect, tableIcons};
