import React from 'react';
import {Box, Typography, ButtonBase, Avatar, TextField, InputAdornment} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {globalStyles} from "../../styles/globalStyles";
import {Colors} from "../../styles/Colors";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    title: {
        ...globalStyles.pageSubTitleSmall,
        marginTop: '4%'
    },
    button: {

    },
    value: {
        margin: '4%',
        fontSize: 16
    },
    helperText: {
        color: Colors.red,
        fontSize: 12,
        textAlign: 'end'
    }
}));

function GoalTextInputComponent(props) {
    const classes = useStyles();
    const {style, isValid, currentValue, onChange, helperText, label, title, unit} = props;

    return (
        <React.Fragment>
            <Box className={classes.row} style={style}>
                <Typography className={classes.title}>{title}</Typography>
                <Box className={classes.row} style={{minWidth: "65%"}}>
                    <TextField margin='dense' label={label} variant="outlined"
                               error={!isValid(currentValue)}
                               value={currentValue}
                               style={{width: '100%'}}
                               onChange={(e) => onChange(e.target.value)}
                               InputProps={{
                                   endAdornment: <InputAdornment position="end">{unit}</InputAdornment>,
                               }}
                    />
                </Box>
            </Box>
            {
                !isValid(currentValue) && (
                    <Typography className={classes.helperText}>{helperText}</Typography>
                )
            }
        </React.Fragment>
    );
}

export {GoalTextInputComponent};
