import React from 'react';
import {Box, Breadcrumbs, Link, Grid, Modal, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from "@material-ui/core";
import CalendarComponent from "../components/main/appointments/calendarComponent";
import AppointmentHead from "../components/main/appointments/appointmentHead";
import AppointmentSubHead from "../components/main/appointments/appointmentSubHead";
import AppointmentCell from "../components/main/appointments/appointmentCell";
import AppointmentAdd from "../components/main/appointments/appointmentAdd";
import {AddForm, RescheduleForm} from "../components/main/appointments/appointmentPopModals";
import {withStyles} from "@material-ui/core/styles";
import {Colors} from "../styles/Colors";
import {
    generateMonthList,
    getPrevMonth,
    getNextMonth,
    addZero,
    firstDayOfMonth,
    lastDayOfMonth
} from "../commonFuncs/calendarFuncs";
import {requestGetAppointments, requestAllPatientsProfile, requestDeleteAppointment} from "../netCalls/requestAppointmentModule";
import Moment from "moment";


const useStyles = () => ({
    container: {
        backgroundColor: Colors.grey,
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        padding: 20,
    },
    verticalContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        height:'100%',
        width:'100%',
    },
    mainTitle:{
        fontSize: 30,
        marginTop: 10,
        marginBottom: 20,
    },
    margin:{
        marginBottom: 10
    }
});

class Appointments extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            selectedDate: new Date(),
            shownMonthDate: new Date(),
            monthList: [],
            addModalOpen: false,
            rescheduleModalOpen: false,
            deleteDialogOpen: false,

            appointmentList: [],
            patientList: [],
        }

        this.selectDate = new Date()
        this.selectAppointment = ''
    }

    componentDidMount() {
        this.init();
    }

    init = () => {
        let today = new Date();

        this.setHaveAppointment(this.state.shownMonthDate, generateMonthList(this.state.shownMonthDate, today))

        this.initAppointments();

        requestAllPatientsProfile()
            .then(response=> {
                console.log('all patients profile : ' + JSON.stringify(response.data));
                this.setState({patientList: response.data})
            })
    }

    setHaveAppointment = (shownMonthDate, monthDayList) => {
        let counter = true;
        let threshold = 0;
        while(counter && threshold < monthDayList.length){
            if(monthDayList[threshold].day){
                counter = false
            }else{
                threshold ++;
            }
        }

        requestGetAppointments(this.generateDate(firstDayOfMonth(shownMonthDate)), this.generateDate(lastDayOfMonth(shownMonthDate)))
            .then(response=> {
                console.log('appointment list month : ' + JSON.stringify(response.data))
                let appointmentList = response.data
                for(let i=0; i<appointmentList.length; i++){
                    if(appointmentList[i].start){
                        let date = Moment(appointmentList[i].start, 'DD/MM/YYYY HH:mm:ss').toDate().getDate()
                        if(date + threshold - 1 < monthDayList.length){
                            monthDayList[date + threshold - 1].hasAppointment = true
                        }
                    }
                }
                this.setState({
                    shownMonthDate: shownMonthDate,
                    monthList: monthDayList
                });
            })
    }

    initAppointments = (selectDate = this.state.selectedDate) => {
        requestGetAppointments(this.generateDate(selectDate), this.generateDate(selectDate))
            .then(response=> {
                this.checkClash(response.data)
                console.log('appointment list : ' + JSON.stringify(response.data))
                this.setState({appointmentList: response.data})
            })
    }

    checkClash = appointmentList => {
        for(let i=0; i<appointmentList.length; i++){
            for(let j=0; j<appointmentList.length; j++) {
                if(i !== j){
                    if(this.generateDateTime(appointmentList[i].start) > this.generateDateTime(appointmentList[j].start)
                        && this.generateDateTime(appointmentList[i].start) < this.generateDateTime(appointmentList[j].end)){
                        appointmentList[i].clash = true
                    }
                    if(this.generateDateTime(appointmentList[i].end) > this.generateDateTime(appointmentList[j].start)
                        && this.generateDateTime(appointmentList[i].end) < this.generateDateTime(appointmentList[j].end)){
                        appointmentList[i].clash = true
                    }
                }
            }
        }

    }

    generateDateTime = datetime => {
        return Moment(datetime, 'DD/MM/YYYY HH:mm:ss')
    }

    generateDate = selectDate => {
        return Moment(selectDate).format('YYYY-MM-DD');
    }


    prevMonth = () => {
        let today = new Date();
        let prevShownMonthDate = getPrevMonth(this.state.shownMonthDate);
        this.setHaveAppointment(prevShownMonthDate, generateMonthList(prevShownMonthDate, today))
    }

    nextMonth = () => {
        let today = new Date();
        let nextShownMonthDate = getNextMonth(this.state.shownMonthDate);
        this.setHaveAppointment(nextShownMonthDate, generateMonthList(nextShownMonthDate, today))
    }

    handleCalendarDateSelect = dateItem => {
        console.log('dateItem : ' + JSON.stringify(dateItem));
        if(dateItem && dateItem.day){
            let newSelectDateStr = Moment(this.state.shownMonthDate).format('YYYY/MM/') + addZero(dateItem.day);
            this.selectDate = Moment(newSelectDateStr, 'YYYY/MM/DD').toDate();
            this.setState({selectedDate: this.selectDate})
            this.initAppointments(this.selectDate);
        }
    }

    closeAddModal = () => {
        this.setState({addModalOpen: false})
        this.initAppointments();
        this.setHaveAppointment(this.state.shownMonthDate, generateMonthList(this.state.shownMonthDate))
    }

    closeRescheduleModal = () => {
        this.setState({rescheduleModalOpen: false})
        this.initAppointments();
        this.setHaveAppointment(this.state.shownMonthDate, generateMonthList(this.state.shownMonthDate))
    }

    closeDeleteDialog = () =>{
        this.setState({deleteDialogOpen: false})
    }

    submitDelete = () => {
        requestDeleteAppointment(this.selectAppointment._id).then(response => {
            this.setState({deleteDialogOpen: false})
            this.initAppointments();
            this.setHaveAppointment(this.state.shownMonthDate, generateMonthList(this.state.shownMonthDate))
        })
    }

    handleEditAppointment = appointment => {
        this.selectAppointment = appointment;
        this.setState({
            rescheduleModalOpen: true
        });
    }

    handleDeleteAppointment = appointment => {
        this.selectAppointment = appointment;
        this.setState({
            deleteDialogOpen: true
        });
    }

    render() {
        const { classes } = this.props;

        return <Box className={classes.container}>
            <Breadcrumbs separator={'>'}>
                <Link color="inherit" href="home"  >
                    <b>Home</b>
                </Link>
                <Link color="inherit" >
                    <b>Appointment</b>
                </Link>
            </Breadcrumbs>

            <Box className={classes.mainTitle} component={'b'}>Appointment</Box>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6} lg={5} xl={4}>
                    <CalendarComponent selectedDate={this.state.selectedDate}
                                       shownMonthDate={this.state.shownMonthDate}
                                       calendarList={this.state.monthList}
                                       prevMonth={this.prevMonth}
                                       nextMonth={this.nextMonth}
                                       handleCalendarDateSelect={this.handleCalendarDateSelect}/>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={7} xl={8}>
                    <Box className={classes.verticalContainer}>
                        <AppointmentHead className={classes.margin} appointmentList={this.state.appointmentList}/>
                        <AppointmentSubHead className={classes.margin} />
                        {this.state.appointmentList && this.state.appointmentList.map((item, index) => (
                            <AppointmentCell className={classes.margin}
                                             appointment={item}
                                             rescheduleFunc={() => {this.handleEditAppointment(item)}}
                                             deleteFunc={() => {this.handleDeleteAppointment(item)}}/>
                        ))}
                        {Moment(this.state.selectedDate).format('YYYYMMDD') >= Moment(new Date()).format('YYYYMMDD') &&
                            (<AppointmentAdd className={classes.margin} addAppointment={() => {
                                this.setState({addModalOpen: true})
                            }}/>)
                        }
                    </Box>
                </Grid>
            </Grid>

            <Modal open={this.state.addModalOpen} onClose={() => {this.closeAddModal()}}>
                <AddForm closeModal={this.closeAddModal} patientList={this.state.patientList} selectDate={this.selectDate} />
            </Modal>

            <Modal open={this.state.rescheduleModalOpen} onClose={() => {this.closeRescheduleModal()}}>
                <RescheduleForm closeModal={this.closeRescheduleModal} appointment={this.selectAppointment} />
            </Modal>

            <Dialog
                open={this.state.deleteDialogOpen}
                onClose={this.closeDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle id="alert-dialog-title">{"Are you sure you want to cancel this appointment?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Canceled Appointment will be removed from system
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeDeleteDialog} color="primary">
                        No
                    </Button>
                    <Button onClick={this.submitDelete} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>

        </Box>
    }

}

export default withStyles(useStyles)(Appointments);



