import React from 'react';
import {Box, ButtonBase, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {Colors} from "../../styles/Colors";

const DAY_FILTER_KEY = 'Day';
const WEEK_FILTER_KEY = 'Week';
const MONTH_FILTER_KEY = 'Month';

const tabs = [
    {name: DAY_FILTER_KEY},
    {name: WEEK_FILTER_KEY},
    {name: MONTH_FILTER_KEY}
];

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: `1px solid ${Colors.lightGreen}`,
        borderRadius: 5
    },
    tab: {
        borderRadius: 5,
    },
    selectedTab: {
        borderRadius: 5,
        fontWeight: 'bold',
        backgroundColor: Colors.lightGreen,
    },
    tabText: {
        fontSize: 16,
        fontWeight: 'inherit',
        padding: 3,
        width: 50
    }
}));

function GraphTimeFilterTabs(props) {
    const {style, currentFilter, setCurrentFilter} = props;
    const classes = useStyles();

    return (
        <Box style={style} className={classes.container}>
            {
                tabs.map(tab =>
                    (<ButtonBase className={currentFilter === tab.name ? classes.selectedTab : classes.tab} onClick={()=>setCurrentFilter(tab.name)}>
                        <Typography className={classes.tabText}>
                            {tab.name}
                        </Typography>
                    </ButtonBase>))
            }
        </Box>
    )
}

export {GraphTimeFilterTabs, DAY_FILTER_KEY, WEEK_FILTER_KEY, MONTH_FILTER_KEY};
