import React from "react";
import {Box, Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Colors} from "../../../styles/Colors";

const AppointmentSubHead = props => {
    const classes = useStyles();

    return (
        <Box className={[classes.container, props.className]} border={1} boxShadow={2}>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Box className={classes.subContainer} component={'b'}>
                        Name
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.subContainer} component={'b'}>
                        Purpose
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.subContainer} component={'b'}>
                        Time
                    </Box>
                </Grid>
                <Grid item xs={3}>
                    <Box className={classes.subContainer} component={'b'}>
                        Action
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default AppointmentSubHead

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        borderRadius: 10,
        borderColor: Colors.grey,
        backgroundColor: Colors.white,
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    subContainer: {
        display: 'flex',
        alignItems: 'center',
        height: '100%',
    }
})
