import React from 'react';
import {Box, Typography, ButtonBase, Avatar} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import {globalStyles} from "../../styles/globalStyles";

const useStyles = makeStyles((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        ...globalStyles.pageSubTitleSmall
    },
    button: {

    },
    value: {
        margin: '4%',
        fontSize: 16
    }
}));

function IncrDecrComp(props) {
    const {onIncrease, onDecrease, currentValue, style} = props;
    const classes = useStyles();
    return (
        <Box className={classes.row} style={style}>
            <ButtonBase className={classes.button} onClick={onIncrease} disableTouchRipple>
                <Avatar src={'images/main/svg/hcw-icon-lightgreen-increase.svg'} />
            </ButtonBase>
            <Typography className={classes.value}>
                {currentValue}
            </Typography>
            <ButtonBase className={classes.button} onClick={onDecrease} disableTouchRipple>
                <Avatar src={'images/main/svg/hcw-icon-lightgreen-decrease.svg'} />
            </ButtonBase>
        </Box>
    );
}

function IncrDecrCompWithText(props) {
    const classes = useStyles();
    const {style} = props;
    return (
        <Box className={classes.row} style={style}>
            <Typography className={classes.title}>{props.title}</Typography>
            <IncrDecrComp {...props} style={{width: '50%'}} />
        </Box>
    );
}

export {IncrDecrComp, IncrDecrCompWithText};
