import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CardMedia } from '@material-ui/core';


const TopBar = props => {
    const classes = useStyles();
    return (
        <Box className={[classes.container, props.className]}>
            <CardMedia className={classes.icon} component="img" src="/images/login/empower_icon.png"/>
        </Box>
    )
};

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
        boxShadow: '0 3px 5px 2px rgba(105, 105, 105, .3)',
        justifyContent: "flex-start",
    },
    icon: {
        width: 100,
        height: '100%',
        aspectRatio:1,
        objectFit: 'contain',
    },
});

export default TopBar
