import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

const minWidth = '340px'

const useStyles = makeStyles(theme => (
    {
        // no styles yet
        formControl: {
            margin: 0,
            minWidth
          },
        select: {
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 15,
            paddingRight: 15,
        }
    }
));

const menuProps = {
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left"
    },
    getContentAnchorEl: null,
    disableAutoFocusItem: true
}

function DropDownSelector(props) {
    const {choices, currentChoiceValue, onChoiceChange, style} = props;

    const handleChange = (event) => {
        onChoiceChange(event.target.value); // propagate to parent
    }

    const classes = useStyles();

    return (
        <FormControl variant='outlined' className={classes.formControl} style={style}>
            <Select classes={{select: classes.select}} value={currentChoiceValue} onChange={handleChange} autoWidth MenuProps={menuProps}>
                {
                    choices.map(choice => (
                        <MenuItem value={choice.value} style={{minWidth}}>{choice.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export {DropDownSelector};
