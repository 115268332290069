const getMonthStr = monthValue => {
    switch (monthValue){
        case 0:
            return 'January';
        case 1:
            return 'February';
        case 2:
            return 'March';
        case 3:
            return 'April';
        case 4:
            return 'May';
        case 5:
            return 'June';
        case 6:
            return 'July';
        case 7:
            return 'August';
        case 8:
            return 'September';
        case 9:
            return 'October';
        case 10:
            return 'November';
        case 11:
            return 'December';
    }
}

const getDayStr = dayValue => {
    switch (dayValue){
        case 0:
            return 'Sun';
        case 1:
            return 'Mon';
        case 2:
            return 'Tue';
        case 3:
            return 'Wed';
        case 4:
            return 'Thu';
        case 5:
            return 'Fri';
        case 6:
            return 'Sat';

    }
}

const generateDayInWeekList = () => {
    let dayList = []
    for(let i=0;i<7;i++){
        dayList.push(getDayStr(i))
    }
    return dayList
}

const firstDayOfMonth = date => {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

const lastDayOfMonth = date => {
    return new Date(date.getFullYear(), date.getMonth()+1, 0);
}

const getPrevMonth = date => {
    return new Date(date.getFullYear(), date.getMonth(), 0);
}

const getNextMonth = date => {
    return new Date(date.getFullYear(), date.getMonth()+1, 1);
}

const isPassDay = (yyyy, MM, dd, today) => {
    if(today.getFullYear() < yyyy){
        return false;
    }else if(today.getFullYear() > yyyy){
        return true;
    }else {
        if(today.getMonth() < MM){
            return false;
        }else if(today.getMonth() < MM){
            return true;
        }else{
            if(today.getDate() <= dd){
                return false;
            }else{
                return true;
            }
        }
    }
}

const generateMonthList = (date, today = new Date()) => {
    let startDate = firstDayOfMonth(date);
    let lastDate = lastDayOfMonth(date);
    console.log('date:' + date.getFullYear() + '/' + date.getMonth() + '/' + date.getDate())
    console.log('startDate:' + startDate.getFullYear() + '/' + startDate.getMonth() + '/' + startDate.getDate())
    console.log('lastDate:' + lastDate.getFullYear() + '/' + lastDate.getMonth() + '/' + lastDate.getDate())
    let list = [];

    let dayInWeekFirst = startDate.getDay();
    let passDay = isPassDay(date.getFullYear(), date.getMonth(), 1, today)
    for(let i=0;i<dayInWeekFirst;i++){
        list.push({
            passDay: passDay,
        });
    }

    for(let i=1;i<=lastDate.getDate();i++){
        list.push({
            day:i,
            hasAppointment:false,
            passDay:isPassDay(date.getFullYear(), date.getMonth(), i, today),
        })
    }

    let futureDay = isPassDay(date.getFullYear(), date.getMonth()+1, 0, today)
    let dayInWeekLast = lastDate.getDay();
    for(let i=0;i<6-dayInWeekLast;i++){
        list.push({
            passDay: futureDay,
        });
    }

    return list;
}

const addZero = num => {
    if(num >= 1 && num <= 9){
        return '0' + num.toString();
    }
    return num.toString();
}

export {getMonthStr, generateDayInWeekList, generateMonthList, firstDayOfMonth, lastDayOfMonth, getPrevMonth, getNextMonth, addZero}
