const conditionImages = {
    hyperglycemia: 'images/main/svg/hcw-icon-navy-hyper.svg',
    hypoglycemia: 'images/main/svg/hcw-icon-navy-hypo.svg',
    pregnant: 'images/main/svg/hcw-icon-navy-preg.svg',
    'newly started insulin' : 'images/main/svg/hcw-icon-navy-insulin.svg',
    priority: 'images/main/svg/hcw-icon-navy-priority.svg',
    'type 1': 'images/main/svg/hcw-icon-navy-type1.svg',
    'type 2': 'images/main/svg/hcw-icon-navy-type2.svg'
}

// Takes in a patientType object and flattens it to obtain the keys for the respective field.
// The conditions and full_conditions field must match the keys inside condition images
// conditions are partial tags that are rendered only in patient table.
// full conditions are rendered inside patient profile card.
// tags are of:
// "newly_started_insulin"
// "steroid"
// "pregnancy"
// "priority"
// "blevel_risk"
// "diabetes_type"
function parsePatient(p) {
    const {patient, patient_type} = p;

    const parsedResult = {
        first_name: patient.first_name,
        conditions: [], //partial
        full_conditions: [], //everything
        diabetes_type: patient_type.diabetes_type,
        id: patient.trial_id,
        imgUrl: patient.imgUrl || "https://img.pngio.com/wearing-a-mask-of-patients-patient-the-patient-cartoon-patient-png-650_1036.png",
        allergies: patient_type.allergies
    }

    for (const [key, value] of Object.entries(patient_type)) {
        if (value) {
            if (key === "newly_started_insulin") {
                parsedResult.conditions.push('newly started insulin');
                parsedResult.full_conditions.push('newly started insulin');
            } else if (key === 'steroid') {
                // got no icon for steroid so we ignore this first.
                parsedResult.full_conditions.push('steroid');
            } else if (key === 'pregnancy') {
                parsedResult.conditions.push('pregnant');
                parsedResult.full_conditions.push('pregnant');
            } else if (key === 'priority') {
                parsedResult.conditions.push('priority');
                parsedResult.full_conditions.push('priority');
            } else if (key === 'blevel_risk') {
                // no icon for this so we skip also
                parsedResult.full_conditions.push('high risk');
            } else if (key === 'diabetes_type') {
                parsedResult.full_conditions.push(`type ${value}`);
            }
        }
    }

    return parsedResult;
}

export {conditionImages, parsePatient};
