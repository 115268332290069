import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Box, CardMedia} from "@material-ui/core";
//components
import TopBar from "../components/Login/topBar";
import LoginComponent from "../components/Login/loginComponent";
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../redux/reduxMapping";
import SecurityQuestionSetupDialog from "../components/Account/SetupSecurityQuestions";


function LoginPage(props) {
    const classes = useStyles();

    return (
        <>
            <TopBar className={classes.topBar}/>
            <Box component="span" className={classes.subContainer}>
                <CardMedia className={[classes.leftThemeImage, classes.minHeight]} component="img" src="/images/login/img_login.png"/>
                <LoginComponent className={[classes.rightContent, classes.minHeight]} />
                <SecurityQuestionSetupDialog submit={props.setSecurityQuestionAnswers} open={props.setup}
                allQuestions={props.allQuestions} cancel={props.logout} cancelText="Log Off" submitText={"Continue"}/>
            </Box>
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    topBar: {
        height: '7%',
        minHeight: 70,
    },
    subContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        height: '93%',
    },
    leftThemeImage: {
        height: '100%',
        width: '60%',
        objectFit: 'contain',
        boxShadow: '0 3px 5px 2px rgba(105, 105, 105, .3)',
        [theme.breakpoints.up('xs')]: {
            display: 'none',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        [theme.breakpoints.up('lg')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('xl')]: {
            display: 'flex',
        },
    },
    rightContent: {
        height: '100%',
        [theme.breakpoints.up('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '40%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '40%',
        },
    },
    minHeight: {
        [theme.breakpoints.up('xs')]: {
            minHeight: 480,
        },
        [theme.breakpoints.up('sm')]: {
            minHeight: 600,
        },
        [theme.breakpoints.up('md')]: {
            minHeight: 720,
        },
        [theme.breakpoints.up('lg')]: {
            minHeight: 720,
        },
        [theme.breakpoints.up('xl')]: {
            minHeight: 800,
        },
    }
}));

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
