import React from 'react';
import {PatientTable, PatientTableToolbar} from '../components/main/patientInfo/patientTable';
import {Linkable} from "../components/main/linkable";
import {PatientProfileCard} from "../components/main/patientInfo/patientProfileCard";
import {PatientInfoQueryView} from "./PatientInfo/PatientInfoQueryView";
import { useLocation, useHistory } from 'react-router-dom';
import {globalStyles} from "../styles/globalStyles";
const qs = require('qs');

const PatientInfo = () => {
    const path = useLocation();

    const queryParams = qs.parse(path.search, { ignoreQueryPrefix: true });
    const baseUrl = path.pathname;

    document.title = "Empower - Patient Info"; // set page title

    return (
        <div style={globalStyles.container}>
            {
                'id' in queryParams ? (
                    <PatientInfoQueryView />
                ) : (
                    <PatientTable />
                )
            }
        </div>
    )
}

export default PatientInfo;
