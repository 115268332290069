import React from "react";
import {Box, CardMedia} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";


const UserCell = props => {
    const classes = useStyles();

    const {usertype, username} = props;

    return (
        <Box className={classes.horizontalContainer} >
            <CardMedia className={classes.headIcon} component="img" src="/images/main/svg/hcw-icon-navy-tabs-info.svg"/>
            <Box className={classes.verticalContainer} >
                <Box className={classes.horizontalContainer}>{usertype}</Box>
                <Box className={classes.horizontalContainer}>{username}</Box>
            </Box>
        </Box>
    )
}

export default UserCell;

const useStyles = makeStyles({
    horizontalContainer:{
        display: 'flex',
        alignItems:'center',
        height:'100%',
    },
    verticalContainer:{
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        height:'100%',
    },
    headIcon: {
        height:30,
        width:30,
        marginLeft: 10,
        marginRight: 10,
        aspectRatio:1,
        objectFit: 'contain',
    },
})
