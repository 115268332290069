import React, {useState} from "react";
import {
    Box,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Button,
    Menu,
    ButtonBase, TextField
} from "@material-ui/core";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {makeStyles} from "@material-ui/core/styles";
import Moment from 'moment';
import {Colors} from "../../../styles/Colors";
import UserCell from "./userCell";
import {requestAddAppointment, requestEditAppointment} from "../../../netCalls/requestAppointmentModule";
import Alert from "@material-ui/lab/Alert";


const AddFormType = 'Add';
const RescheduleFormType = 'Reschedule';

const AppointmentForm = props => {
    const classes = useStyles();

    const {closeModal, patientList, appointment, formType, selectDate} = props;

    const initTitle = appointment => {
        if(appointment){
            return appointment.title;
        }
        return ''
    }

    const initStartDate = () => {
        if(appointment){
             return Moment(appointment.start, 'DD-MM-YYYY HH:mm:ss')
        }
        if(selectDate){
             return Moment(selectDate);
        }
        return Moment(new Date());
    }

    const initEndDate = () => {
        if(appointment){
            return Moment(appointment.end, 'DD-MM-YYYY HH:mm:ss')
        }
        if(selectDate){
            return Moment(selectDate).add(1, 'hours');
        }
        return Moment(new Date()).add(1, 'hours');
    }

    const [title, setTitle] = useState(initTitle(appointment));
    const [showAs, setShowAs] = useState('');
    const [startDate, setStartDate] = useState(initStartDate());
    const [endDate, setEndDate] = useState(initEndDate());
    const [patientCompleteInfo, setPatientCompleteInfo] = useState(props.patient)
    const [error, setError] = useState('');

    const [patientMenuOpen, setPatientMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false)

    const handleShowAsChange = (event) => {
        setShowAs(event.target.value);
    };

    const openPatientMenu = (event) => {
        setAnchorEl(event.currentTarget);
        setPatientMenuOpen(true);
    }

    const handlePatientMenuItemClick = item => {
        setPatientCompleteInfo(item);
        setPatientMenuOpen(false);
    }

    const checkInputValidation = () => {
        if(formType === AddFormType) {
            if(title === '') {
                console.log('need a purpose');
                setError('need a purpose');
                return false;
            }
            if (!patientCompleteInfo) {
                console.log('need select a patient')
                setError('need select a patient')
                return false;
            }
            if (Moment(startDate).format('HH:mm:ss') >= Moment(endDate).format('HH:mm:ss')) {
                console.log('start date should not be later than end date');
                setError('start date should not be later than end date');
                return false;
            }
        }else{
            if(title === '') {
                console.log('need a purpose');
                setError('need a purpose');
                return false;
            }
            if (Moment(startDate).format('HH:mm:ss') >= Moment(endDate).format('HH:mm:ss')) {
                console.log('start date should not be later than end date');
                setError('start date should not be later than end date');
                return false;
            }
        }
        return true;
    }

    const formatDate = date => {
        return Moment(date).format('DD/MM/YYYY HH:mm:ss')
    }

    const formatDateEnd = (start, end) => {
        return Moment(start).format('DD/MM/YYYY') + ' ' + Moment(end).format('HH:mm:ss')
    }

    const handleSubmit = () => {
        if(formType === AddFormType){
            if(checkInputValidation()) {
                requestAddAppointment(patientCompleteInfo.patient.username, title, formatDate(startDate), formatDateEnd(startDate, endDate))
                    .then(response => {
                            console.log(JSON.stringify(response.data))
                            closeModal();
                        }
                    )
            }
        }else if(formType === RescheduleFormType){
            if(checkInputValidation()) {
                requestEditAppointment(appointment._id, title, formatDate(startDate), formatDateEnd(startDate, endDate))
                    .then(response => {
                        console.log(JSON.stringify(response.data))
                        closeModal();
                    })
            }
        }
    }

    return (
        <>
            <Box className={classes.screen}>
            <Box className={[classes.container, classes.card]} border={1} boxShadow={2} >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Box className={classes.mainTitle} component={'b'}>Appointment</Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                            style={{width: '100%', height: 60}}
                            label="Purpose (eg: Review Report)"
                            variant="outlined"
                            value={title}
                            error={props.loginError}
                            onChange={(e) => {setTitle(e.target.value)}}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box className={[classes.card, classes.subContainer]} border={1}>
                            {formType === AddFormType ?
                                (<ButtonBase onClick={openPatientMenu}>
                                    {patientCompleteInfo ?
                                        (<UserCell usertype={'Patient'}
                                                   username={patientCompleteInfo.patient.first_name}/>) :
                                        (<Box className={classes.emptyBox}>Select Patient</Box>)
                                    }
                                </ButtonBase>) : (
                                    appointment && (<UserCell usertype={'Patient'}
                                              username={appointment.patient.first_name}/>)
                                )
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Box className={[classes.card, classes.subContainer]} border={1}>
                            <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                label="Start Date"
                                disablePast={true}
                                value={startDate}
                                onChange={(date) => {setStartDate(date)}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Box className={[classes.card, classes.subContainer]} border={1}>
                            <KeyboardTimePicker
                                disableToolbar
                                variant="inline"
                                ampm={false}
                                margin="normal"
                                label="Start Time"
                                value={startDate}
                                onChange={(date) => {setStartDate(date)}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <Box className={[classes.card, classes.subContainer]} border={1}>
                            {formType === AddFormType ?
                                (<ButtonBase>
                                    {(patientCompleteInfo && (patientCompleteInfo.caregiver !== null ?
                                            (<UserCell usertype={'Caregiver'}
                                                       username={patientCompleteInfo.caregiver.first_name}/>) :
                                            (<Box className={classes.emptyBox}>No Caregiver Assigned</Box>)
                                    ))}
                                </ButtonBase>) : (
                                    appointment && appointment.caregiver && (
                                        <UserCell usertype={'Caregiver'}
                                                  username={appointment.caregiver.first_name}/>)

                                )
                            }
                        </Box>
                    </Grid>
                    <Grid item xs={0} sm={0} md={0} lg={3} xl={3} />
                    <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                        <Box className={[classes.card, classes.subContainer]} border={1}>
                            <KeyboardTimePicker
                                disableToolbar
                                variant="inline"
                                ampm={false}
                                margin="normal"
                                label="End Time"
                                value={endDate}
                                onChange={(date) => {setEndDate(date)}}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <InputLabel>Show As</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={showAs}
                                onChange={handleShowAsChange}
                                label="Type">
                                <MenuItem value={'Available'}>Available</MenuItem>
                                <MenuItem value={'Busy'}>Busy</MenuItem>
                                <MenuItem value={'Out of Office'}>Out of Office</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} className={classes.bottomLayer} >
                        <Button onClick={() => {closeModal()}}>
                            Cancel
                        </Button>
                        <Button className={classes.positionBtn} onClick={handleSubmit}>
                            {formType}
                        </Button>
                    </Grid>
                </Grid>
                </MuiPickersUtilsProvider>
                {error && <Alert  style={{marginTop: 20, marginLeft:'18%', width: '60%'}} severity="error">{error}</Alert>}
            </Box>
            </Box>
            <Menu
                anchorEl={anchorEl}
                open={patientMenuOpen}
                onClose={() => {setPatientMenuOpen(false)}}>

                {patientList && patientList.map((item, index) => (
                    <MenuItem className={classes.menuItem} onClick={() => {handlePatientMenuItemClick(item)}}>
                        <UserCell usertype={'Patient'} username={item.patient.first_name} />
                    </MenuItem>
                ))}

            </Menu>
        </>
    )
}

const AddForm = props => {
    const {closeModal, patientList, selectDate} = props;
    return <AppointmentForm closeModal={closeModal} patientList={patientList} formType={AddFormType} selectDate={selectDate}/>
}

const RescheduleForm = props => {
    const {closeModal, appointment} = props;
    return <AppointmentForm closeModal={closeModal} appointment={appointment} formType={RescheduleFormType} />
}

export {AddForm, RescheduleForm}

const useStyles = makeStyles((theme) => ({
    screen:{
        display: 'flex',
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItem: 'center',
        [theme.breakpoints.up('xs')]: {
            paddingTop: '3vh',
            paddingBottom: '3vh',
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: '3vh',
            paddingBottom: '3vh',
        },
        [theme.breakpoints.up('md')]: {
            paddingTop: '15vh',
            paddingBottom: '15vh',
        },
        [theme.breakpoints.up('lg')]: {
            paddingTop: '20vh',
            paddingBottom: '20vh',
        },
        [theme.breakpoints.up('xl')]: {
            paddingTop: '20vh',
            paddingBottom: '20vh',
        }
    },
    container: {
        display: 'inline-block',
        padding: 20,
        [theme.breakpoints.up('xs')]: {
            width: '90%',
            height:'90%',
        },
        [theme.breakpoints.up('sm')]: {
            width: '90%',
            height:'90%',
        },
        [theme.breakpoints.up('md')]: {
            width: '60%',
            height:'60%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '50%',
            height:'50%',
        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
            height:'50%',
        },
    },
    card: {
        borderRadius: 10,
        borderColor: Colors.grey,
        backgroundColor: Colors.white,
    },
    subContainer: {
        padding: 6,
        display: 'flex',
        alignItem: 'center',
        minHeight: 44,
    },
    mainTitle:{
        fontSize: 30,
        marginTop: 10,
        marginBottom: 20,
    },
    formControl: {
        width: '100%',
    },
    bottomLayer:{
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
    },
    positionBtn:{
        backgroundColor: Colors.red,
    },
    emptyBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        fontSize: 18,
    }
}))
