import React, {useState} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ButtonBase, Modal, Box, Typography, Grid, Avatar, TextField} from "@material-ui/core";
import {Colors} from "../../styles/Colors";
import {IncrDecrCompWithText} from "./IncrementDecrementComponent";
import {weeklyWeightGoals} from "./weight/WeightGoalInputs";
import {DropDownSelector} from "../main/dropDownSelector";
import {GoalTextInputComponent} from "./TextInputComponent";
import {allIsValid, isMoreThanTwoDecimalPlaces, isNumeric, withRestriction} from "../../commonFuncs/graphFuncs";
import {requestUpsertNewPreconfiguredGoal} from "../../netCalls/requestsGoalModule";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%'
    },
    goalNameTextInput: {
        width: '100%',
    },
    actionButtonsContainer: {
        paddingLeft: '20%',
        paddingRight: '20%'
    },
    backButton: {
        width: '100%',
        backgroundColor: Colors.grey,
        borderRadius: 8,
        height: 42
    },
    backButtonText: {
        fontWeight: 'bold',
    },
    addButton: {
        width: '100%',
        backgroundColor: Colors.loginColorArr[1],
        borderRadius: 8,
        height: 42
    },
    addButtonText: {
        fontWeight: 'bold',
        color: '#fff'
    },
    disabledAddButton: {
        width: '100%',
        backgroundColor: Colors.loginColorArr[1],
        borderRadius: 8,
        height: 42,
        opacity: 0.5
    }
}));

// the base layout with goal name text input, add and go back button
function AddBaseGoalComponent(props) {
    const {onBack, onSubmit, goalName, setGoalName, children, isDisabled} = props;
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField label={'Goal Name'}
                               margin='dense'
                               variant='outlined'
                               className={classes.goalNameTextInput}
                               value={goalName}
                               onChange={(e) => setGoalName(e.target.value)} />
                </Grid>
                {
                    children
                }
            </Grid>
            <Grid container spacing={6} className={classes.actionButtonsContainer}>
                <Grid item xs={6}>
                    <ButtonBase className={classes.backButton} onClick={onBack}>
                        <Typography className={classes.backButtonText}>Back</Typography>
                    </ButtonBase>
                </Grid>
                <Grid item xs={6}>
                    <ButtonBase className={isDisabled? classes.disabledAddButton : classes.addButton}
                                disabled={isDisabled} onClick={onSubmit}>
                        <Typography className={classes.addButtonText}>Add</Typography>
                    </ButtonBase>
                </Grid>
            </Grid>
        </Box>
    )
}

// Note that for components below, the values are in string format. - should parse first before submitting.
function AddBloodGlucoseGoalComponent(props) {
    const {onBack, onDone} = props;
    const classes = useStyles();
    const [goalName, setGoalName] = useState('');
    const [minValue, setMinValue] = useState(null);
    const [maxValue, setMaxValue] = useState(null);

    const isDisabled = goalName === '' || !allIsValid([minValue, maxValue]);

    const onSubmit = () => {
        // todo the logic for making async call to add
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            max_bg: parseFloat(maxValue),
            min_bg: parseFloat(minValue),
        }, 'blood_glucose')
            .then(resp => onDone());
    }

    return (
        <AddBaseGoalComponent onBack={onBack} onSubmit={onSubmit} goalName={goalName} setGoalName={setGoalName} isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Min Value'}
                                        unit={'mmol/L'}
                                        currentValue={minValue}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setMinValue)}
                                        isValid={isNumeric}
                                        helperText={'Min value must be numeric'} />
                <GoalTextInputComponent title={'Max Value'}
                                        unit={'mmol/L'}
                                        currentValue={maxValue}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setMaxValue)}
                                        isValid={isNumeric}
                                        helperText={'Max value must be numeric'} />
            </Grid>
        </AddBaseGoalComponent>
    );
}

function AddFoodIntakeGoalComponent(props) {
    const {onBack, onDone} = props;
    const classes = useStyles();
    const [goalName, setGoalName] = useState('');
    const [calorieGoal, setCalorieGoal] = useState(null);
    const [carbohydrateGoal, setCarbohydrateGoal] = useState(null);
    const [proteinGoal, setProteinGoal] = useState(null);
    const [fatGoal, setFatGoal] = useState(null);

    const isDisabled = goalName === '' || !allIsValid([calorieGoal, carbohydrateGoal, proteinGoal, fatGoal]);

    const onSubmit = () => {
        // todo the logic for making async call to add
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            carbs: parseFloat(carbohydrateGoal),
            fats: parseFloat(fatGoal),
            protein: parseFloat(proteinGoal),
            calories: parseFloat(calorieGoal),
        }, 'food')
            .then(resp => onDone());
    }

    return (
        <AddBaseGoalComponent onBack={onBack} onSubmit={onSubmit} goalName={goalName} setGoalName={setGoalName} isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Cal'}
                                        unit={'kcal'}
                                        currentValue={calorieGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setCalorieGoal)}
                                        isValid={isNumeric}
                                        helperText={'Calories must be numeric'} />
                <GoalTextInputComponent title={'Carbs'}
                                        unit={'g'}
                                        currentValue={carbohydrateGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setCarbohydrateGoal)}
                                        isValid={isNumeric}
                                        helperText={'Carbohydrates burnt must be numeric'} />
                <GoalTextInputComponent title={'Protein'}
                                        unit={'g'}
                                        currentValue={proteinGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setProteinGoal)}
                                        isValid={isNumeric}
                                        helperText={'Protein must be numeric'} />
                <GoalTextInputComponent title={'Fat'}
                                        unit={'g'}
                                        currentValue={fatGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setFatGoal)}
                                        isValid={isNumeric}
                                        helperText={'Fat must be numeric'} />
            </Grid>
        </AddBaseGoalComponent>
    );
}

function AddMedicationGoalComponent(props) {
    const {onBack, onDone} = props;
    const classes = useStyles();
    const [goalName, setGoalName] = useState('');

    const isDisabled = false;

    const onSubmit = () => {
        // todo the logic for making async call to add
        onDone();
    }

    return (
        <AddBaseGoalComponent onBack={onBack} onSubmit={onSubmit} goalName={goalName} setGoalName={setGoalName} isDisabled={isDisabled}>
            <Grid item xs={6}>
                {null}
            </Grid>
        </AddBaseGoalComponent>
    );
}

function AddWeightGoalComponent(props) {
    const {onBack, onDone} = props;
    const classes = useStyles();
    const [goalName, setGoalName] = useState('');
    const [weightGoal, setWeightGoal] = useState(null);
    const [weeklyWeightGoal, setWeeklyWeightGoal] = useState(weeklyWeightGoals[0].value);

    const isDisabled = goalName === '' || !allIsValid([weightGoal]);

    const onSubmit = () => {
        // todo the logic for making async call to add
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            goal_weight: parseFloat(weightGoal),
            weekly_offset: parseFloat(weeklyWeightGoal),
        }, 'weight')
            .then(resp => onDone());
    }

    return (
        <AddBaseGoalComponent onBack={onBack} onSubmit={onSubmit} goalName={goalName} setGoalName={setGoalName} isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Target Weight'}
                                        unit={'kg'}
                                        currentValue={weightGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setWeightGoal)}
                                        isValid={isNumeric}
                                        helperText={'Weight must be numeric'} />
            </Grid>
            <Grid item xs={6}>
                <DropDownSelector choices={weeklyWeightGoals}
                                  currentChoiceValue={weeklyWeightGoal}
                                  onChoiceChange={setWeeklyWeightGoal}
                                  style={{width: '100%'}}
                />
            </Grid>
        </AddBaseGoalComponent>
    );
}

function AddActivityGoalComponent(props) {
    const {onBack, onDone} = props;
    const classes = useStyles();
    const [goalName, setGoalName] = useState('');
    const [durationGoal, setDurationGoal] = useState(null);
    const [calorieBurntGoal, setCalorieBurntGoal] = useState(null);

    const isDisabled = goalName === '' || !allIsValid([durationGoal, calorieBurntGoal]);

    const onSubmit = () => {
        // todo the logic for making async call to add
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            cal_burnt: parseFloat(calorieBurntGoal),
            duration: parseFloat(durationGoal),
        }, 'activity')
            .then(resp => onDone());
    }

    return (
        <AddBaseGoalComponent onBack={onBack} onSubmit={onSubmit} goalName={goalName} setGoalName={setGoalName} isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Duration'}
                                        unit={'min'}
                                        currentValue={durationGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setDurationGoal)}
                                        isValid={isNumeric}
                                        helperText={'Duration must be numeric'} />
                <GoalTextInputComponent title={'Cal Burnt'}
                                        unit={'kcal'}
                                        currentValue={calorieBurntGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setCalorieBurntGoal)}
                                        isValid={isNumeric}
                                        helperText={'Calories burnt must be numeric'} />
            </Grid>
        </AddBaseGoalComponent>
    );
}

function AddStepsGoalComponent(props) {
    const {onBack, onDone} = props;
    const classes = useStyles();
    const [goalName, setGoalName] = useState('');
    const [minStepsGoal, setMinStepsGoal] = useState(null);

    const isDisabled = goalName === '' || !allIsValid([minStepsGoal]);

    const onSubmit = () => {
        // todo the logic for making async call to add
        requestUpsertNewPreconfiguredGoal({
            name: goalName,
            steps: parseFloat(minStepsGoal)
        }, 'steps')
            .then(resp => onDone());
    }

    return (
        <AddBaseGoalComponent onBack={onBack} onSubmit={onSubmit} goalName={goalName} setGoalName={setGoalName} isDisabled={isDisabled}>
            <Grid item xs={6}>
                <GoalTextInputComponent title={'Min Steps'}
                                        unit={'steps'}
                                        currentValue={minStepsGoal}
                                        onChange={withRestriction(isMoreThanTwoDecimalPlaces, setMinStepsGoal)}
                                        isValid={isNumeric}
                                        helperText={'Duration must be numeric'} />
            </Grid>
        </AddBaseGoalComponent>
    );
}

export {AddBloodGlucoseGoalComponent, AddFoodIntakeGoalComponent, AddMedicationGoalComponent, AddWeightGoalComponent, AddActivityGoalComponent, AddStepsGoalComponent};
